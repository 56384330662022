import { useConfig } from '@application/composables/config'
import type { IContentfulParam } from '@infrastructure/contentful/data-sources/contentful-param.interface'
import { ContentfulClient } from './data-sources/contentful-client'

const { getEnv, getLocales } = useConfig()

const contentfulEnv = {
  contentfulSpaceId: getEnv('CONTENTFUL_SPACE_ID') || '',
  contentfulApiKey: {},
  environmentName: {}
} as IContentfulParam
const contentfulPreviewEnv = {
  contentfulSpaceId: getEnv('CONTENTFUL_SPACE_ID_PREVIEW') || '',
  contentfulApiKey: {},
  environmentName: {}
} as IContentfulParam

const locales = getLocales()
locales?.forEach((locale) => {
  contentfulEnv.contentfulApiKey[locale] = getEnv(`CONTENTFUL_${locale.toUpperCase()}_API_KEY`) || ''
  contentfulEnv.environmentName[locale] = getEnv(`CONTENTFUL_${locale.toUpperCase()}_ENVIRONMENT_NAME`) || ''
  contentfulPreviewEnv.contentfulApiKey[locale] = getEnv(`CONTENTFUL_${locale.toUpperCase()}_API_KEY_PREVIEW`) || ''
  contentfulPreviewEnv.environmentName[locale] =
    getEnv(`CONTENTFUL_${locale.toUpperCase()}_ENVIRONMENT_NAME_PREVIEW`) || ''
})
export const contentfulClient = new ContentfulClient(locales, contentfulEnv, contentfulPreviewEnv)
