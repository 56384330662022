import { CategoryConfig } from '@contexts/config/domain/category-config.model'
import { ContentfulMapper } from '@infrastructure/contentful'
import type { TypeCategoryConfig, TypeCommonAdaptativeImage } from '@invivodf/contentful-types-retail'
import type { CommonAdaptativeImageItem } from '@/domain/contentful/common-adaptative-image-item.model'

export class CategoryConfigMapper {
  static extract(
    categoryConfigDTO: TypeCategoryConfig<'WITHOUT_UNRESOLVABLE_LINKS', string> | null
  ): CategoryConfig | null {
    if (!categoryConfigDTO?.fields) return null

    let items = null

    if (categoryConfigDTO?.fields?.brands?.length) {
      items = (categoryConfigDTO.fields.brands as TypeCommonAdaptativeImage<'WITHOUT_UNRESOLVABLE_LINKS', string>[])
        .filter((obj) => !!obj?.fields)
        .map((item) => ContentfulMapper.extractAdaptativeImage(item))
        .filter((obj): obj is CommonAdaptativeImageItem => !!obj)
    }

    return new CategoryConfig(categoryConfigDTO.fields.title, categoryConfigDTO.fields.metaDescription, items)
  }
}
