import type { IConfigState } from '@application/store/config/interface'

export default (): IConfigState => ({
  title: '',
  logo: null,
  categories: [],
  alertInfo: null,
  storeHeader: null,
  innerWidth: null,
  promotionContent: null
})
