import { DEFAULT_PHONE_NUMBER_INDICATOR } from '@application/composables/phone-number'

export class Address {
  constructor(
    public firstName: string,
    public lastName: string,
    public line1: string,
    public line2: string,
    public companyName: string,
    public postalCode: string,
    public city: string,
    public country: string,
    public mobilePhoneNumber: string,
    public mobilePhoneNumberIndicator: string,
    public defaultAddress: boolean,
    public billingAddress: boolean,
    public id?: string
  ) {}

  static isValidFirstName(firstName: string): boolean {
    const isValidLength = firstName.length > 0

    return isValidLength
  }

  static isValidLastName(lastName: string): boolean {
    const isValidLength = lastName.length > 0

    return isValidLength
  }

  static isValidLine1(line1: string): boolean {
    const isValidLength = line1.length > 0

    return isValidLength
  }

  static isValidLine2(line2: string): boolean {
    const isValidLength = line2.length <= 35

    return isValidLength
  }

  static isValidCity(city: string): boolean {
    const isValidLength = city.length > 0

    return isValidLength
  }

  static isValidCompanyName(companyName: string): boolean {
    const isValidLength = companyName.length <= 35

    return isValidLength
  }

  static isValidPostalCode(postalCode: string): boolean {
    const isOnlyNumbers = /^\d+$/.test(postalCode)
    const isValidLength = postalCode.length === 5

    return isOnlyNumbers && isValidLength
  }

  static isValidEmail(email: string): boolean {
    const isValidLength = email.length > 0
    const isValidFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

    return isValidLength && isValidFormat
  }

  static getEmptyAddress(): Address {
    return {
      firstName: '',
      lastName: '',
      line1: '',
      line2: '',
      companyName: '',
      postalCode: '',
      city: '',
      mobilePhoneNumber: '',
      mobilePhoneNumberIndicator: DEFAULT_PHONE_NUMBER_INDICATOR,
      country: 'France',
      defaultAddress: false,
      billingAddress: false
    }
  }
}
