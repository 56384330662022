export class Utils {
  /**
   * Capitalize a string content.
   * @param {string} value - the string to handle.
   * @param {boolean} [onlyFirstWord=true] - indicates if all the string's words should be capitalized.
   * @returns {string} a computed string.
   */
  static capitalize(value: string, onlyFirstWord = true): string {
    return (value || '')
      .split(/[\s]/)
      .map((word, index) => {
        if (onlyFirstWord && index !== 0) {
          return word
        }
        return `${(word[0] || '').toUpperCase()}${word.slice(1)}`
      })
      .join(' ')
  }

  /**
   * Transforms a string content to a slug.
   * HTML tags are also stripped.
   * @param {string} value - the string to convert.
   * @returns {string} a lowercased slug.
   */
  static slugify(value: string): string {
    return (
      value &&
      value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/(<([^>]+)>)/gi, '')
        .replace(/[^\w]+/g, '-')
        .split('-')
        .filter((character) => Boolean(character))
        .join('-')
        .toLowerCase()
    )
  }

  static transformSentenceToId(sentence: string | null): string | null {
    if (!sentence) return null
    return Utils.slugify(sentence).replace(/^[0-9]+-/, '')
  }

  static isValidUrl(url: string | null | undefined): boolean {
    if (!url) return false
    try {
      const validUrl = new URL(url)
      return validUrl.protocol === 'http:' || validUrl.protocol === 'https:'
    } catch (e) {
      return false
    }
  }
}
