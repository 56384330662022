import type { IUserRepository } from '@contexts/user/infrastructure/interfaces/i-user.repository'
import type { User } from '@contexts/user/domain/user.model'
import type { IUseCookie } from '@/application/composables/cookie'

export class SaveAnonymousUser {
  constructor(public readonly userRepository: IUserRepository) {}

  public execute(user: User, cookie: IUseCookie): Promise<void> {
    return this.userRepository.saveAnonymousUser(user, cookie)
  }
}
