import type { IStoreLocatorRepository } from '@contexts/store-locator/domain/store-locator-repository.interface'
import type { Store } from '@invivoretail/module-store-locator-sdk'
import type { IStoreCoordinates } from '../../domain/coordinates.interface'

export class GetStores {
  constructor(public readonly storeLocatorRepository: IStoreLocatorRepository) {}

  async execute(coordinates: IStoreCoordinates, onlyEnrolled: boolean): Promise<Store[] | []> {
    return this.storeLocatorRepository.fetchStores(coordinates, onlyEnrolled)
  }
}
