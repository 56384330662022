import { authRequestService } from '@application/composables/auth-request'
import { PostAddress } from '@contexts/user/use-cases/PostAddress'
import { UserRepository } from '@contexts/user/infrastructure/user.repository'
import { UserDataSource } from '@contexts/user/infrastructure/data-sources/user.data-source'
import { useConfig } from '@application/composables/config'
import { GetAnonymousUser } from '@contexts/user/use-cases/GetAnonymousUser'
import { SaveAnonymousUser } from '@contexts/user/use-cases/SaveAnonymousUser'
import { useRandomId } from '@application/composables/utils/useRandomId/useRandomId'

import { GetCurrentLoyalty } from '@contexts/user/use-cases/get-current-loyalty/get-current.loyalty.usecase'
import { GetCurrentUser } from './use-cases/GetCurrentUser'
import { DeleteAddress } from './use-cases/DeleteAddress'
import { UpdateAddress } from './use-cases/UpdateAddress'
import { UpdateUserInformation } from './use-cases/UpdateUserInformation'
import { ResetPassword } from './use-cases/ResetPassword'
import { ToggleIsDefaultAddress } from './use-cases/ToggleIsDefaultAddress'
import { UpdateUserLoyaltyCard } from './use-cases/UpdateUserLoyaltyCard'
import { GetDefaultAddress } from './use-cases/GetDefaultAddress'
import { GetBillingAddress } from './use-cases/GetBillingAddress'
import { UpdateOptins } from './use-cases/update-optins/update-optins'

const { getUsersApiUrl } = useConfig()
const { generate: generateId } = useRandomId()

const userDataSource = new UserDataSource(authRequestService, getUsersApiUrl)
const userRepository = new UserRepository(userDataSource, generateId)

const getCurrentUser = new GetCurrentUser(userRepository)
const postAddress = new PostAddress(userRepository)
const deleteAddress = new DeleteAddress(userRepository)
const updateAddress = new UpdateAddress(userRepository)
const updateUserInformation = new UpdateUserInformation(userRepository)
const toggleIsDefaultAddress = new ToggleIsDefaultAddress(userRepository)
const resetPassword = new ResetPassword(userRepository)
const getAnonymousUser = new GetAnonymousUser(userRepository)
const saveAnonymousUser = new SaveAnonymousUser(userRepository)
const updateLoyaltyCard = new UpdateUserLoyaltyCard(userRepository)
const getDefaultAddress = new GetDefaultAddress(userRepository)
const getBillingAddress = new GetBillingAddress(userRepository)
const updateOptins = new UpdateOptins(userRepository)
const getCurrentLoyalty = new GetCurrentLoyalty(userRepository)

export {
  getCurrentUser,
  postAddress,
  deleteAddress,
  updateAddress,
  updateUserInformation,
  toggleIsDefaultAddress,
  resetPassword,
  getAnonymousUser,
  saveAnonymousUser,
  updateLoyaltyCard,
  getDefaultAddress,
  getBillingAddress,
  updateOptins,
  getCurrentLoyalty
}
