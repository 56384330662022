import type { StoreAddress, OpeningHours } from '@invivoretail/module-store-locator-sdk'

export interface ICurrentStore {
  name: string
  shortName: string
  id: string
  slug: string
  openingHours?: OpeningHours | null
  address?: StoreAddress | null
  groupId?: string
}

export enum CountryCode {
  ES = 'ES',
  FR = 'FR'
}
