type IAnalyticsPageCategorization = {
  pathPattern: RegExp
  category: string
  i18n: string
}
const analyticsPageCategorizations: IAnalyticsPageCategorization[] = [
  {
    pathPattern: /^\/$/,
    category: 'home',
    i18n: 'home'
  },
  {
    pathPattern: /^\/c\//,
    category: 'category_page',
    i18n: 'category-page'
  },
  {
    pathPattern: /^\/p\//,
    category: 'product_page',
    i18n: 'product-page'
  },
  {
    pathPattern: /^\/recherche/,
    category: 'search_results_page',
    i18n: 'search-results-page'
  },
  {
    pathPattern: /^\/conseils-idees/,
    category: 'advice_and_ideas',
    i18n: 'advice-and-ideas'
  },
  {
    pathPattern: /^\/plan-du-site/,
    category: 'others',
    i18n: 'sitemap'
  },
  {
    pathPattern: /^\/magasins/,
    category: 'shops',
    i18n: 'shops'
  },
  {
    pathPattern: /^\/catalogue/,
    category: 'catalog',
    i18n: 'catalog'
  },
  {
    pathPattern: /^\/mon-compte/,
    category: 'my_account',
    i18n: 'my-account'
  },
  {
    pathPattern: /^\/panier/,
    category: 'cart',
    i18n: 'cart'
  },
  {
    pathPattern: /^\/achat\/livraison/,
    category: 'checkout_shipping_ways',
    i18n: 'checkout-shipping-ways'
  },
  {
    pathPattern: /^\/achat\/paiement/,
    category: 'checkout_payment_methods',
    i18n: 'checkout-payment-methods'
  },
  {
    pathPattern: /^\/commande\/confirmation/,
    category: 'order_confirmation',
    i18n: 'order-confirmation'
  },
  {
    pathPattern: /^\/politique-de-confidentialite/,
    category: 'others',
    i18n: 'privacy-policy'
  },
  {
    pathPattern: /^\/mentions-legales/,
    category: 'others',
    i18n: 'legals'
  },
  {
    pathPattern: /^\/cgv/,
    category: 'others',
    i18n: 'sale-terms'
  }
]
const analyticsPageCategorizationDefault = 'others'

Object.freeze(analyticsPageCategorizations)
Object.freeze(analyticsPageCategorizationDefault)

export { analyticsPageCategorizations, analyticsPageCategorizationDefault }
