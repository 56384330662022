import { Table } from '@domain/contentful/table.model'
import { CommonLinkItem } from '@domain/contentful/common-link-item.model'
import { CommonImageItem } from '@domain/contentful/common-image-item.model'
import { CommonYoutubeVideo } from '@domain/contentful/common-youtube-video.model'
import { CommonAdaptativeImageItem } from '@domain/contentful/common-adaptative-image-item.model'
import type { Asset, EntryCollection } from 'contentful'
import { CommonListLink } from '@domain/contentful/common-list-link.model'
import { ArticleCategory } from '@domain/contentful/article-category.model'
import { CommonPdfItem } from '@domain/contentful/common-pdf-item.model'
import type {
  TypeArticleCategory,
  TypeArticleCategorySkeleton,
  TypeCommonAdaptativeImage,
  TypeCommonListLink,
  TypeLinkItem
} from '@invivodf/contentful-types-retail'
import type { NodeData } from '@contentful/rich-text-types'

export class ContentfulMapper {
  static extractLink(linkItem: TypeLinkItem<'WITHOUT_LINK_RESOLUTION', string> | null): CommonLinkItem | null {
    if (!linkItem?.fields) return null
    return new CommonLinkItem(linkItem.fields.label, linkItem.fields.url)
  }

  static extractImage(asset: Asset<'WITHOUT_UNRESOLVABLE_LINKS', string> | null): CommonImageItem | null {
    if (!asset?.fields?.file?.url || !asset?.fields?.file?.details?.image) return null

    const [description, credit] = asset!.fields.description?.split('#') || []
    return new CommonImageItem(
      description || '',
      `https:${asset!.fields.file.url}`,
      asset!.fields.file.details.image,
      credit || null
    )
  }

  static extractYoutubeVideo(
    video: TypeLinkItem<'WITHOUT_UNRESOLVABLE_LINKS', string> | null
  ): CommonYoutubeVideo | null {
    if (!video || !video.fields) return null
    const url = video.fields.url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
    const formattedId = url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0]
    return new CommonYoutubeVideo(video.fields.label, formattedId)
  }

  static extractPDF(asset: Asset<'WITHOUT_UNRESOLVABLE_LINKS', string> | null): CommonPdfItem | null {
    if (!asset?.fields?.file?.url) return null
    return new CommonPdfItem(
      asset.fields.description || '',
      `https:${asset.fields.file.url}`,
      asset.fields.file.contentType
    )
  }

  static extractAdaptativeImage(
    item: TypeCommonAdaptativeImage<'WITHOUT_UNRESOLVABLE_LINKS', string> | null
  ): CommonAdaptativeImageItem | null {
    if (!item?.fields) return null
    return new CommonAdaptativeImageItem(
      item.fields.title,
      ContentfulMapper.extractLink(item.fields.link || null),
      ContentfulMapper.extractImage(item.fields.imageDesktop || null),
      ContentfulMapper.extractImage(item.fields.imageMobile || null),
      item.fields.description,
      item.fields.promotionId,
      item.fields.buttonText
    )
  }

  static extractListLink(
    listLinkDTO: TypeCommonListLink<'WITHOUT_UNRESOLVABLE_LINKS', string> | null
  ): CommonListLink | null {
    if (!listLinkDTO || !listLinkDTO.fields) return null

    return new CommonListLink(
      listLinkDTO.fields.title,
      ContentfulMapper.extractImage(listLinkDTO.fields.icon || null),
      listLinkDTO.fields.links
        ?.map((link) => ContentfulMapper.extractLink(link || null))
        .filter((obj): obj is CommonLinkItem => !!obj) || []
    )
  }

  static extractDate(date: string | null): string | null {
    if (!date) return null
    const generatedDate = new Date(date)
    // TODO: pass the local as argument
    return new Intl.DateTimeFormat('fr-FR').format(generatedDate)
  }

  static extractArticleCategories(
    articleCategoryCollectionDto: EntryCollection<TypeArticleCategorySkeleton> | null
  ): ArticleCategory[] | null {
    const articleCategoryEntriesDTO = articleCategoryCollectionDto && articleCategoryCollectionDto.items

    if (!articleCategoryEntriesDTO || !articleCategoryEntriesDTO.length) return null
    return articleCategoryEntriesDTO
      .map((item) =>
        ContentfulMapper.extractArticleCategory(item as TypeArticleCategory<'WITHOUT_UNRESOLVABLE_LINKS', string>)
      )
      .filter((obj): obj is ArticleCategory => !!obj)
  }

  static extractArticleCategory(
    articleCategoryEntryDto: TypeArticleCategory<'WITHOUT_UNRESOLVABLE_LINKS', string> | null
  ): ArticleCategory | null {
    if (!articleCategoryEntryDto?.fields) return null
    const parentCategory: ArticleCategory | null = ContentfulMapper.extractArticleCategory(
      articleCategoryEntryDto.fields.parentCategory || null
    )
    return new ArticleCategory(
      articleCategoryEntryDto.fields.name || '',
      articleCategoryEntryDto.fields.slug,
      parentCategory,
      articleCategoryEntryDto.fields.order
    )
  }

  static extractArticleMainCategory(
    articleCategoryEntryDto: TypeArticleCategory<'WITHOUT_UNRESOLVABLE_LINKS', string> | undefined
  ): ArticleCategory | null {
    if (!articleCategoryEntryDto || !articleCategoryEntryDto.fields) return null
    if (articleCategoryEntryDto.fields.parentCategory) {
      return this.extractArticleMainCategory(articleCategoryEntryDto.fields.parentCategory)
    }
    return this.extractArticleCategory(articleCategoryEntryDto)
  }

  static extractRichtextTable(tableDto: NodeData[] | null): Table | null {
    if (!tableDto || tableDto[0].content.length > 6) return null
    const table = [...tableDto]
    const subHeaders = table.splice(0, 1)[0].content?.map((column: NodeData) => column.content[0].content[0].value)
    const rows = table.map((row) => row.content.map((cell: NodeData) => cell.content))
    const headers: Array<string> = []
    rows.forEach((row) => headers.push(row[0][0].content[0].value!))
    return new Table(headers, subHeaders, rows)
  }
}
