import type { IStoreListItem, IStores } from '@contexts/store-locator/domain/stores.interface'
import { STORE_DEPARTMENTS } from '@contexts/store-locator/infrastructure/data-sources/mappers/store-departments.constants'
import type { BasicStore } from '@invivoretail/module-store-locator-sdk'

export class StoreMapper {
  static extractStores(stores: BasicStore[]): IStores {
    const storesByCity = StoreMapper.extractStoresByCity(stores)
    const storesByDepartment = StoreMapper.extractStoresByDepartment(stores)

    return { stores, storesByCity, storesByDepartment }
  }

  static extractStoresByCity(allStores: BasicStore[]): IStoreListItem[] {
    if (!allStores || !allStores.length) return []

    const allCities: string[] = Array.from(
      new Set(
        allStores
          .filter((store) => store.country === 'FR')
          .map((store) => store.referenceCities)
          .flat()
      )
    ).filter((city): city is string => !!city)

    const storesByCities: IStoreListItem[] = []
    allCities.forEach((city: string) => {
      if (city) {
        const stores = allStores.filter((store) => store.referenceCities?.includes(city))
        storesByCities.push({
          label: city,
          stores
        })
      }
    })

    return storesByCities.sort((a, b) => a.label.localeCompare(b.label))
  }

  static extractStoresByDepartment(allStores: BasicStore[]): IStoreListItem[] {
    if (!allStores || !allStores.length) return []

    const allDepartmentCodes: string[] = Array.from(
      new Set(
        allStores.filter((store) => store.country === 'FR').map((store) => `0${store.zipcode}`.slice(-5).slice(0, 2))
      )
    ).sort((a, b) => a.localeCompare(b))

    const formattedDataByDerpartment: IStoreListItem[] = []
    allDepartmentCodes.forEach((code) => {
      const stores = allStores.filter((store) => `0${store.zipcode}`.slice(-5).startsWith(code))
      formattedDataByDerpartment.push({
        label: `${code} - ${STORE_DEPARTMENTS[code]}`,
        stores
      })
    })

    return formattedDataByDerpartment
  }
}
