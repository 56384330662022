import type { ICartRepository } from '@contexts/cart/domain/cart-repository.interface'
import type { CartId, ICart } from '@contexts/cart/domain/cart.model'
import type { AddLineItemQueryV2 } from '@invivodf/module-cart-retail-interfaces'

interface IAddLineParam {
  cartRepository: ICartRepository
}

export class AddLine {
  public readonly cartRepository: ICartRepository

  constructor({ cartRepository }: IAddLineParam) {
    this.cartRepository = cartRepository
  }

  async execute(cartId: CartId | null, line: AddLineItemQueryV2): Promise<ICart> {
    return this.cartRepository.addLine(cartId, line)
  }
}
