import revive_payload_client_4sVQNw7RlN from "/builds/invivoretail/enseignes/enseignes.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/invivoretail/enseignes/enseignes.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/invivoretail/enseignes/enseignes.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/builds/invivoretail/enseignes/enseignes.com/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/invivoretail/enseignes/enseignes.com/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/invivoretail/enseignes/enseignes.com/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/invivoretail/enseignes/enseignes.com/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_2LpVRIemQY from "/builds/invivoretail/enseignes/enseignes.com/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/invivoretail/enseignes/enseignes.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/invivoretail/enseignes/enseignes.com/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/invivoretail/enseignes/enseignes.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ghbUAjaD3n from "/builds/invivoretail/enseignes/enseignes.com/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/builds/invivoretail/enseignes/enseignes.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/builds/invivoretail/enseignes/enseignes.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _01_vuex_OKgf7aXVUg from "/builds/invivoretail/enseignes/enseignes.com/src/application/plugins/01.vuex.ts";
import _02_axeptio_client_hhwFkY0lHg from "/builds/invivoretail/enseignes/enseignes.com/src/application/plugins/02.axeptio.client.ts";
import _03_gtm_client_aiF11srDhz from "/builds/invivoretail/enseignes/enseignes.com/src/application/plugins/03.gtm.client.ts";
import _04_abtasty_client_g5e0DFnQPi from "/builds/invivoretail/enseignes/enseignes.com/src/application/plugins/04.abtasty.client.ts";
import global_client_TMqeGKdFMA from "/builds/invivoretail/enseignes/enseignes.com/src/application/plugins/global.client.ts";
import path_t36do0cXFw from "/builds/invivoretail/enseignes/enseignes.com/src/application/plugins/path.ts";
import router_client_EnpdrLHExQ from "/builds/invivoretail/enseignes/enseignes.com/src/application/plugins/router.client.ts";
import sentry_client_obg5wkN8ZM from "/builds/invivoretail/enseignes/enseignes.com/src/application/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ghbUAjaD3n,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  _01_vuex_OKgf7aXVUg,
  _02_axeptio_client_hhwFkY0lHg,
  _03_gtm_client_aiF11srDhz,
  _04_abtasty_client_g5e0DFnQPi,
  global_client_TMqeGKdFMA,
  path_t36do0cXFw,
  router_client_EnpdrLHExQ,
  sentry_client_obg5wkN8ZM
]