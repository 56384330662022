import type { ResolvedField } from 'contentful'
import type { CommonImageItem } from '@domain/contentful/common-image-item.model'
import type { TypeCommonSeoSkeleton } from '@invivodf/contentful-types-retail'

export type CommonSeoBackground = string
export const CommonSeoBackgroundTransparent: CommonSeoBackground = 'transparent'
export const CommonSeoBackgroundClassic: CommonSeoBackground = 'classic'
export const CommonSeoBackgroundInfo: CommonSeoBackground = 'info'

export type CommonSeoPosition = string
export const CommonSeoPositionNone: CommonSeoPosition = 'none'
export const CommonSeoPositionLeft: CommonSeoPosition = 'left'
export const CommonSeoPositionCenter: CommonSeoPosition = 'center'

export class CommonSeo {
  isCommonSeo: boolean

  constructor(
    public title: string,
    public headerIcon: CommonImageItem | null,
    public content: ResolvedField<TypeCommonSeoSkeleton['fields']['content'], 'WITHOUT_UNRESOLVABLE_LINKS', string>,
    public background: CommonSeoBackground,
    public position: CommonSeoPosition
  ) {
    this.isCommonSeo = true
  }

  toJSON(): CommonSeo {
    return { ...this }
  }
}
