import type { ICartRepository } from '@contexts/cart/domain/cart-repository.interface'
import type { ICart } from '@contexts/cart/domain/cart.model'
import type { IUpdateLineQuantityQuery } from '../domain/update-line-quantity-query.interface'

interface IUpdateLineQuantity {
  cartRepository: ICartRepository
}

export class UpdateLineQuantity {
  public readonly cartRepository: ICartRepository

  constructor({ cartRepository }: IUpdateLineQuantity) {
    this.cartRepository = cartRepository
  }

  async execute(updateLineQuantityQuery: IUpdateLineQuantityQuery): Promise<ICart> {
    return this.cartRepository.updateLineQuantity(updateLineQuantityQuery)
  }
}
