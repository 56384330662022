import { computed, inject } from '#imports'
import type { Ref } from 'vue'
import type { VueI18n } from 'vue-i18n'
import {
  computeAmount,
  formatDiscount,
  formatPrice,
  getComputedAmountPerUnit
} from '@application/composables/pricing/usePrice/utils'

export default function usePrice(
  amount: Ref<number>,
  discountValue: Ref<number>,
  discountUnit: Ref<string>,
  quantity?: Ref<number>
): {
  computedAmount: Ref<number>
  computedAmountPerUnit: Ref<number>
  price: Ref<string>
  pricePerUnit: Ref<string>
  formattedDiscount: Ref<string>
} {
  const i18n = inject<VueI18n>('i18n')

  if (!i18n) throw new Error('i18n is not defined')

  const code = i18n.t('metadata.language-code') as string
  const currency = i18n.t('metadata.currency-code') as string

  const computedAmount = computed((): number => computeAmount(amount.value, 2))

  const computedAmountPerUnit = computed((): number => getComputedAmountPerUnit(amount.value, quantity?.value || 0, 2))

  const price = computed((): string => formatPrice(computedAmount.value, code, currency))

  const pricePerUnit = computed((): string => formatPrice(computedAmountPerUnit.value, code, currency))

  const formattedDiscount = computed<string>((): string =>
    formatDiscount(discountValue.value, discountUnit.value, code, currency, i18n.t)
  )

  return { computedAmount, computedAmountPerUnit, price, pricePerUnit, formattedDiscount }
}
