import type { IAuthenticationRepository } from '../domain/authentication-repository.interface'
import type { AccessToken } from '../domain/token.model'

export class GetAccessToken {
  constructor(private readonly authenticationRepository: IAuthenticationRepository) {}

  async execute(): Promise<AccessToken> {
    return this.authenticationRepository.getAccessToken()
  }
}
