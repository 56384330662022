import type { MongoClient, Db } from 'mongodb'
import { getConfigFor } from './config'
import { checkDataOnline } from './utils/online'
import type { Cache } from './utils/cache.interface'
import { getMongoConfig } from './utils/url'

const PREFIX_MONGO_COLLECTION = 'front-request-json-ttl'
const INDEX_TTL_NAME = 'lastModifiedDate'

const API_CACHE = '/api/v1/mg-cache/'
const DISABLE_MONGO_CACHE = process.env.NUXT_DISABLE_REQUEST_CACHE === 'true'

export class MongoCache implements Cache {
  private cache?: MongoClient | null

  private MONGO_URI: string

  private MONGO_DB: string | undefined

  constructor() {
    if (DISABLE_MONGO_CACHE) {
      this.cache = undefined
      this.MONGO_URI = ''
      return
    }
    /* eslint-disable-next-line no-console */
    console.log('MongoCache constructor')

    const mongoParams = getMongoConfig()

    this.MONGO_URI = mongoParams.MONGO_URI
    this.MONGO_DB = mongoParams.MONGO_DB

    if (process.server && mongoParams.MONGO_URL && !(process.env.NODE_ENV === 'development')) {
      import('mongodb')
        .then(({ MongoClient, ServerApiVersion }) => {
          this.cache = new MongoClient(this.MONGO_URI, {
            serverApi: {
              version: ServerApiVersion.v1,
              strict: true,
              deprecationErrors: true
            }
          })
        })
        .catch((e) => {
          /* eslint-disable-next-line no-console */
          console.error(e)
        })
    }
  }

  async get<T>(name?: string, options?: { checkOnline: boolean }): Promise<T | null> {
    const db = await this.getDb()

    if (!db || !name) return Promise.resolve(null)

    let entry

    try {
      entry = await MongoCache.getCollection(getConfigFor(name).ttl, db).findOne({
        _id: name
      })
    } catch (e) {
      /* eslint-disable-next-line no-console */
      console.error(e)
    }

    if (!entry && options?.checkOnline) {
      return checkDataOnline(name, API_CACHE)
    }

    return entry?.data
  }

  async set<T = null>(name: string, value: T): Promise<null> {
    const db = await this.getDb()

    if (!db) return Promise.resolve(null)

    if (!name || !value) return Promise.resolve(null)

    const config = getConfigFor(name)

    if (config.activate) {
      try {
        await MongoCache.getCollection(config.ttl, db).createIndex(
          { [INDEX_TTL_NAME]: 1 },
          { expireAfterSeconds: config.ttl }
        )
        await MongoCache.getCollection(config.ttl, db).insertOne({
          _id: name,
          data: value,
          [INDEX_TTL_NAME]: new Date()
        })
      } catch (e) {
        /* eslint-disable-next-line no-console */
        console.error(e)
      }
    }

    return Promise.resolve(null)
  }

  get isActivated(): boolean {
    return Boolean(this.MONGO_URI.includes('mongodb'))
  }

  // PRIVATE functions

  private async getDb(): Promise<Db | undefined> {
    return this.cache
      ?.connect()
      .then((client) => client.db(this.MONGO_DB))
      .catch((error) => {
        /* eslint-disable-next-line no-console */
        console.error('Error connecting to MongoDB', error)
        return undefined
      })
  }

  private static getCollection(ttl = 3600, db: Db) {
    const MONGO_COLLECTION = MongoCache.mongoCollectioName(ttl)
    return db?.collection(MONGO_COLLECTION)
  }

  private static mongoCollectioName(ttl = 3600) {
    return `${PREFIX_MONGO_COLLECTION}-${ttl}`
  }
}

export const mongoCacheInstance = new MongoCache()
