import axeptio from './axeptio'
import cart from './cart'
import config from './config'
import shop from './shop'
import user from './user'

export const getStoreModules = () => ({
  axeptio,
  cart,
  config,
  shop,
  user
})
