import type { Hit, MultipleQueriesResponse } from '@algolia/client-search'
import type { IAlgoliaRecommendationDTO } from '@infrastructure/algolia/algolia-recommendation/data-sources/algolia-recommendation.dto'
import { ProductRecommend } from '@domain/product-recommend/product-recommend.model'
import type { ImageDataset } from '@application/types/image-dataset'
import { ProductRecommendAvailability } from '@domain/product-recommend/product-recommend-availability.model'
import type { ICurrentStore } from '@/domain/current-store/current-store.interface'
import type { IProductAvailability, IProductPrice } from '@/domain/product/product.interface'

type IHit<T> = Hit<T> & { _score: number }
export class AlgoliaRecommendationMapper {
  public static extractImage(description: string, url: string): ImageDataset | null {
    if (!description || !url) return null
    return {
      description,
      sources: [
        {
          url
        }
      ]
    } as ImageDataset
  }

  public static extractPriceAndDiscount = (
    selectedStore: ICurrentStore | null,
    product: IAlgoliaRecommendationDTO,
    isFID: boolean | null
  ): IProductPrice => {
    const pricingForStore = product[`store-${selectedStore?.id}`]

    const offerPrice = pricingForStore?.[isFID ? 'fid' : 'withoutFid']

    if (!pricingForStore || !offerPrice) {
      return {
        isIndicativePrice: true,
        price: product.indicativePrice
      }
    }

    return {
      isIndicativePrice: false,
      price: offerPrice.price || product.indicativePrice,
      discount: offerPrice.hasDiscount
        ? {
            amount: offerPrice.discount?.value,
            unit: offerPrice.discount?.unit,
            originalPrice: pricingForStore.priceBeforeDiscount
          }
        : undefined
    }
  }

  public static extract(
    multipleResponse: MultipleQueriesResponse<IAlgoliaRecommendationDTO> | null,
    currentStore: ICurrentStore | null,
    isFid = false
  ): ProductRecommend[][] {
    if (!multipleResponse?.results?.length) return []
    return multipleResponse.results.map((searchResponse) =>
      this.extractRecommendations(searchResponse.hits, currentStore, isFid)
    )
  }

  public static extractRecommendations(
    recommendations: Hit<IAlgoliaRecommendationDTO>[],
    currentStore: ICurrentStore | null,
    isFid = false
  ): ProductRecommend[] {
    if (!recommendations?.length) return []
    return recommendations.map((hit) => {
      const { isIndicativePrice, price, discount } = this.extractPriceAndDiscount(currentStore, hit, isFid)
      return new ProductRecommend(
        hit.objectID,
        hit.categories,
        this.extractImage(hit.name, hit.thumbnailUrl),
        isIndicativePrice,
        hit.name,
        price,
        hit.slug,
        hit.brand,
        discount
      )
    })
  }

  public static extractRecommendationAvailabilities(
    recommendations: Hit<IAlgoliaRecommendationDTO>[],
    availabilities: IProductAvailability[] | null,
    currentStore: ICurrentStore | null,
    isFid = false
  ): ProductRecommendAvailability[] {
    if (!recommendations?.length || !availabilities?.length) return []
    return recommendations.map((hit, index) => {
      const currentAvailibility = availabilities[index]
      const { isIndicativePrice, price, discount } = this.extractPriceAndDiscount(currentStore, hit, isFid)
      return new ProductRecommendAvailability(
        hit.objectID,
        hit.categories,
        this.extractImage(hit.name, hit.thumbnailUrl),
        isIndicativePrice,
        hit.name,
        price,
        hit.slug,
        currentAvailibility,
        hit.isSellableOnline,
        hit.brand,
        discount
      )
    })
  }

  public static getRelevantRecommendations(
    multipleResponse: MultipleQueriesResponse<IAlgoliaRecommendationDTO> | null,
    productIds: string[]
  ): Hit<IAlgoliaRecommendationDTO>[] {
    const allHits: IHit<IAlgoliaRecommendationDTO>[] = multipleResponse?.results
      .map(({ hits }) => hits)
      .flat() as IHit<IAlgoliaRecommendationDTO>[]

    if (!allHits?.length) return []
    const filteredHits = allHits
      .filter((hit) => !productIds.includes(hit.objectID))
      // eslint-disable-next-line no-underscore-dangle
      .sort((a, b) => b._score - a._score)
      .slice(0, 8)
    if (!filteredHits.length) return []
    return filteredHits as Hit<IAlgoliaRecommendationDTO>[]
  }
}
