import type { GetterTree } from 'vuex'
import type { CategoryConfig } from '@contexts/config/domain/category-config.model'
import type { IRootState } from '../state.interface'
import type { IConfigState } from './interface'

const configGetters: GetterTree<IConfigState, IRootState> = {
  getCategoryMetaDescription:
    (state: IConfigState) =>
    (category: string): string => {
      return (
        state?.categories?.find((c: CategoryConfig) => c.title.toLowerCase() === category.toLowerCase())
          ?.metaDescription || ''
      )
    }
}

export default configGetters
