import { defineNuxtPlugin, useHead } from '#imports'
import { useConfig } from '@application/composables/config'

export default defineNuxtPlugin(() => {
  const { getEnv } = useConfig()

  useHead(
    {
      script: [
        {
          vmid: 'ab-tasty',
          src: `https://try.abtasty.com/${getEnv('ABTASTY_TOKEN')}.js`,
          defer: true
        }
      ]
    },
    { mode: 'client' }
  )
})
