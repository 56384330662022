import type { Address } from '@/domain/address'
import type { IUserRepository } from '../infrastructure/interfaces/i-user.repository'

export class GetDefaultAddress {
  constructor(public readonly userRepository: IUserRepository) {}

  async execute(): Promise<Address> {
    return this.userRepository.getDefaultAddress()
  }
}
