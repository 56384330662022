type CommonImageItemDimension = {
  width: number
  height: number
}

export class CommonImageItem {
  constructor(
    public description: string,
    public src: string,
    public dimensions: CommonImageItemDimension,
    public credit: string | null
  ) {}

  toJSON(): CommonImageItem {
    return { ...this }
  }
}
