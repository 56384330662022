import type { IUserRepository } from '@contexts/user/infrastructure/interfaces/i-user.repository'
import type { User } from '@contexts/user/domain/user.model'
import type { IUseCookie } from '@/application/composables/cookie'

export class GetAnonymousUser {
  constructor(public readonly userRepository: IUserRepository) {}

  public execute(cookie: IUseCookie): Promise<User> {
    return this.userRepository.getAnonymousUser(cookie)
  }
}
