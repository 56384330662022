import type { IStoreLocatorRepository } from '@contexts/store-locator/domain/store-locator-repository.interface'
import type { StoreDetails } from '@invivoretail/module-store-locator-sdk'

export class GetStoreBySlug {
  constructor(public readonly storeLocatorRepository: IStoreLocatorRepository) {}

  async execute(slug?: string): Promise<StoreDetails | null> {
    if (!slug) return null
    return this.storeLocatorRepository.fetchStoreBySlug(slug)
  }
}
