import { useRoute } from '#imports'
import { useConfig } from '@application/composables/config'
import { useSsrNuxtApp } from '../utils/use-ssr-nuxt-app'

export interface ILocation {
  constructPageLocation: (path?: string) => string
  getPageLocation: () => string
  getPageReferrer: () => string
}

export const useLocation = (): ILocation => {
  const route = useRoute()
  const { getBaseUrl } = useConfig()
  const { from, req } = useSsrNuxtApp()

  const constructPageLocation = (path?: string) => `${getBaseUrl()}${path || ''}`
  const getPageLocation = (): string => `${getBaseUrl()}${route?.path || ''}`
  const getPageReferrer = (): string => {
    const referrer = req?.headers?.referrer || req?.headers?.referer || null
    if (referrer && typeof referrer === 'string') {
      return referrer
    }

    if (referrer && Array.isArray(referrer)) {
      return referrer[0]
    }

    return `${getBaseUrl()}${from?.value?.path || ''}`
  }

  return {
    constructPageLocation,
    getPageLocation,
    getPageReferrer
  }
}
