import { useConfig } from '@application/composables/config'
import logger from '@utils/logger/logger'
import { defineNuxtPlugin } from '#imports'
import type { NuxtApp } from 'nuxt/app'

const MAX_RETRY = 5
const DELAY_BETWEEN_RETRY = 300

function initGTM(googleTagManagerId: string | null, nbRetry: number) {
  // eslint-disable-next-line no-underscore-dangle
  if (window._gtm_inject && googleTagManagerId) {
    // eslint-disable-next-line no-underscore-dangle
    window._gtm_inject(googleTagManagerId)
  } else if (nbRetry < MAX_RETRY) {
    setTimeout(() => {
      initGTM(googleTagManagerId, nbRetry + 1)
    }, DELAY_BETWEEN_RETRY)
  } else {
    logger.error('GTM not initialized', new Error('GTM not initialized'))
  }
}

export default defineNuxtPlugin((nuxtApp: NuxtApp) => {
  const { getEnv } = useConfig()
  const googleTagManagerId = getEnv('GOOGLE_TAG_MANAGER_ID')
  const nbRetry = 0

  if (nuxtApp.$store?.state.axeptio.ready) {
    initGTM(googleTagManagerId, nbRetry)
  }
  nuxtApp.provide('gtm', {
    push: (data: unknown) => window.dataLayer && window.dataLayer.push(data)
  })
})
