import type { User } from '@contexts/user/domain/user.model'
import type { IUserRepository } from '../infrastructure/interfaces/i-user.repository'

export class GetCurrentUser {
  constructor(public readonly userRepository: IUserRepository) {}

  async execute(): Promise<User> {
    return this.userRepository.getCurrentUser()
  }
}
