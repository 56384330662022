import type { IConfigRepository } from '@contexts/config/domain/config-repository.interface'
import type { CommonConfig } from '@contexts/config/domain/config.model'

export class GetConfig {
  constructor(public readonly configRepository: IConfigRepository) {}

  async execute(locale: string): Promise<CommonConfig | null> {
    return this.configRepository.findConfig(locale)
  }
}
