import type { InternalAxiosRequestConfig } from 'axios'
import type { AuthenticationRepository } from '@contexts/auth/infrastructure/authentication.repository'
import type { IRequest } from '../request'
import { RequestService } from '../request/request.service'
import type { IAuthRequestParam } from './auth-request-param.interface'

export class AuthRequestService extends RequestService implements IRequest {
  private authenticationRepository: AuthenticationRepository

  constructor({ authenticationRepository }: IAuthRequestParam) {
    super()
    this.authenticationRepository = authenticationRepository

    this.httpClient.interceptors.request.use((config) => this.accessTokenInterceptor(config))
  }

  private async accessTokenInterceptor(config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> {
    const isUserAuthenticated = await this.authenticationRepository.isAuthenticated()
    if (isUserAuthenticated) {
      const accessToken = await this.authenticationRepository.getAccessToken()
      if (accessToken !== null) {
        config.headers.set('Authorization', `Bearer ${accessToken}`)
      }
    }

    return config
  }
}
