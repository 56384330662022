import type { EntryCollection } from 'contentful'
import { CommonConfig } from '@contexts/config/domain/config.model'
import { CategoryConfigMapper } from '@contexts/config/infrastructure/data-sources/mappers/category-config.mapper'
import type { CategoryConfig } from '@contexts/config/domain/category-config.model'
import { CommonSeoMapper } from '@infrastructure/contentful/mappers/common-seo/common-seo.mapper'
import { ContentfulMapper } from '@infrastructure/contentful'
import type { TypeCategoryConfig, TypeCommonConfig, TypeCommonConfigSkeleton } from '@invivodf/contentful-types-retail'
import { PromotionContentMapper } from '@/infrastructure/contentful/mappers/promotion-content/promotion-content.mapper'

export class CommonConfigMapper {
  static extract(configDTOContentFul: EntryCollection<TypeCommonConfigSkeleton> | null): CommonConfig | null {
    const configDTO = configDTOContentFul?.items?.length
      ? (configDTOContentFul.items[0] as TypeCommonConfig<'WITHOUT_UNRESOLVABLE_LINKS', string>)
      : null

    let categories = null
    if (configDTO?.fields?.categoriesConfig?.length) {
      categories = (configDTO.fields.categoriesConfig as TypeCategoryConfig<'WITHOUT_UNRESOLVABLE_LINKS', string>[])
        .map((item) => CategoryConfigMapper.extract(item))
        .filter((obj): obj is CategoryConfig => !!obj)
    }
    if (!configDTO || !configDTO.fields) return null
    return new CommonConfig(
      configDTO!.fields.title,
      ContentfulMapper.extractAdaptativeImage(configDTO.fields.logo || null),
      categories,
      CommonSeoMapper.extract(configDTO.fields.alertInfo || null),
      ContentfulMapper.extractImage(configDTO.fields.storeHeader || null),
      PromotionContentMapper.extract(configDTO)
    )
  }
}
