import type { ImageDataset } from '@application/types/image-dataset'
import type { IProductAvailability, IProductDiscount, IProductMeta } from '@domain/product/product.interface'

export class ProductRecommendAvailability {
  constructor(
    public id: string,
    public categories: string[],
    public image: ImageDataset | null,
    public isIndicativePrice: boolean,
    public name: string,
    public price: number,
    public slug: string,
    public availability: IProductAvailability | null,
    public isSellableOnline: boolean,
    public brand?: string,
    public discount?: IProductDiscount,
    public meta?: IProductMeta
  ) {}

  toJSON() {
    return { ...this }
  }
}
