import type { ProductDiscountScope } from '@domain/product/product.interface'

export interface AlgoliaOfferDiscount {
  value?: number
  unit?: string
  segment?: string
  minQuantity?: number
  scope?: ProductDiscountScope
}

export interface AlgoliaOfferPrice {
  price?: number
  hasDiscount: boolean
  discount?: AlgoliaOfferDiscount
}

export interface AlgoliaOffer {
  withoutFid: AlgoliaOfferPrice
  fid: AlgoliaOfferPrice
  priceBeforeDiscount?: number
}

export type AlgoliaProductReview = {
  scoreAverage: number
  count: number
}

export enum AlgoliaProductFamily {
  PRODUCT_MPN = 'produit_mpn',
  RETAIL_NATIONAL = 'produit_marque_nationale',
  MARKETPLACE = 'produit_marketplace'
}

export default interface AlgoliaProduct {
  objectID: string
  categories: string[]
  name: string
  slug: string
  thumbnailUrl: string
  indicativePrice: number
  brand?: string
  modelId?: string
  hasStockForHomeDelivery: boolean
  isSellableOnline: boolean
  storesWithOrderableOnlineProduct: string[]
  storesWithStock: string[]
  [key: `store-${string}`]: AlgoliaOffer
  shopName: string
  productFamily: AlgoliaProductFamily
  __queryID?: string
  __position?: number
  productReview?: AlgoliaProductReview
}
