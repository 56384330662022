import type { AutocompleteAddress, IStoreLocatorSDK } from '@invivoretail/module-store-locator-sdk'

export type IAddressAutocompleteRepository = {
  fetchAddressAutocomplete(query: string, locale: 'fr-FR' | 'es-ES'): Promise<AutocompleteAddress[]>
}

export class AddressAutocompleteRepository implements IAddressAutocompleteRepository {
  constructor(public sdk: IStoreLocatorSDK) {}

  async fetchAddressAutocomplete(query: string, locale: 'fr-FR' | 'es-ES'): Promise<AutocompleteAddress[]> {
    return this.sdk.addressAutocomplete(query, locale)
  }
}
