import type { RedirectLoginResult } from '@auth0/auth0-spa-js'
import type { IAuthenticationRepository } from '../domain/authentication-repository.interface'
import type { ILoginParamsDTO } from '../domain/login-dto.model'

export class RetrieveAccessTokenAfterLogin {
  constructor(private readonly authenticationRepository: IAuthenticationRepository) {}

  async execute(): Promise<RedirectLoginResult<ILoginParamsDTO>> {
    return this.authenticationRepository.handleRedirect()
  }
}
