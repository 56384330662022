import logger from '@utils/logger/logger'
import type { ProductSheetOfferDiscount } from '@contexts/product/domain/product-sheet/product-sheet-offer.model'
import type { IProductDiscount } from '@/domain/product/product.interface'
import { ProductDiscountScope } from '@/domain/product/product.interface'

/**
 * Compute an amount, based on passed decimals with a math floor/ceil on the last one.
 * @param {number} amount - the amount to compute.
 * @param {number} decimals - the number of decimals.
 * @returns {number} a trimmed amount.
 */
export const computeAmount = (amount: number, decimals: number): number => {
  if (Number.isNaN(amount) || Number.isNaN(decimals) || amount === undefined) return NaN
  if (amount === null) return 0

  return !Number.isNaN(amount) ? Number(amount?.toFixed(decimals) || 0) : NaN
}

/**
 * Format an amount to a price, based on Intl.NumberFormat.
 * @param {number} amount - the amount to format.
 * @param {string} code - the language code to use. Invalid value will throw an error.
 * @param {string} currency - the currency to use. Invalid value will throw an error.
 * @returns {string} a formatted price.
 */
export const formatPrice = (amount: number, code: string, currency: string): string => {
  if (!(code || currency)) {
    logger.error('formatPrice', 'Invalid language code or currency code')
    return ''
  }
  return new Intl.NumberFormat(code, {
    style: 'currency',
    currency
  })
    .format(amount)
    .replace(/\s/g, '\u202F')
}

/**
 * Get an amount from a total amount and a quantity, then compute this amount.
 * Uses computeAmount.
 * @param {number} amount - the total amount.
 * @param {number} quantity - the quantity.
 * @param {number} [decimals=0] - the number of decimals. Default value is zero.
 * @see computeAmount
 * @returns {number} a computed amount.
 */
export const getComputedAmountPerUnit = (amount: number, quantity: number, decimals = 0): number => {
  if (!amount || !quantity) return 0
  return computeAmount(amount / quantity, decimals)
}

export const formatDiscount = (
  value: number,
  unit: string,
  code: string,
  currency: string,
  translate: (s: string, options?: Record<string, unknown>) => string
): string => {
  if (!value) return ''

  if (value === 100 && unit === '%') return translate('common.pricing-discount-full')

  if (unit === '%') return `-\u202F${value}\u202F${unit}`

  return `-\u202F${formatPrice(value, code, currency)}`
}

export const computeProductSheetDiscountText = (
  discount: ProductSheetOfferDiscount,
  translate: (s: string, o: Record<string, unknown>) => string
): string => {
  const { groupSize, amount, unit } = discount || {}
  const key = amount === 100 && unit === '%' ? 'product.discount.xieme-full' : 'product.discount.xieme'
  return groupSize ? translate(key, { x: groupSize }) : ''
}

export const computeDiscountText = (
  discount: IProductDiscount | undefined,
  translate: (s: string, o: Record<string, unknown>) => string
): string | number | undefined => {
  const { minQuantity, originalPrice, amount, unit } = discount ?? {}
  const isFull = Boolean(amount === 100 && unit === '%')
  const key = isFull ? 'product.discount.xieme-full' : 'product.discount.xieme'
  return minQuantity ? translate(key, { x: minQuantity }) : originalPrice
}

export const isCrossedDiscount = (discount: IProductDiscount = {}): boolean => {
  const { amount, scope } = discount
  return Boolean(amount && scope === ProductDiscountScope.ARTICLE)
}

export default {
  computeAmount,
  computeProductSheetDiscountText,
  formatPrice,
  getComputedAmountPerUnit,
  isCrossedDiscount
}
