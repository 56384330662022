import type { CountryCode } from 'libphonenumber-js'

interface PhoneIndicator {
  countryCode: CountryCode
  indicator: string
  countryTradKey: string
  flag: string
}

export const DEFAULT_PHONE_NUMBER_INDICATOR = 'FR|+33'
export const INDICATOR_SEPARATOR = '|'

export const formatPhoneIndicatorValue = (phoneNumberIndicator: PhoneIndicator): string => {
  return `${phoneNumberIndicator.countryCode}${INDICATOR_SEPARATOR}${phoneNumberIndicator.indicator}`
}

export const splitPhoneIndicatorValue = (
  phoneNumberIndicator: string = DEFAULT_PHONE_NUMBER_INDICATOR
): [CountryCode, string] => {
  const pindicator = phoneNumberIndicator || DEFAULT_PHONE_NUMBER_INDICATOR
  const [countryCode, indicator] = pindicator.split(INDICATOR_SEPARATOR)
  return [countryCode as CountryCode, indicator]
}
export const PHONE_INDICATORS: PhoneIndicator[] = [
  { indicator: '+213', countryTradKey: 'phone-indicators.algeria', flag: '🇩🇿', countryCode: 'DZ' },
  { indicator: '+376', countryTradKey: 'phone-indicators.andorra', flag: '🇦🇩', countryCode: 'AD' },
  { indicator: '+244', countryTradKey: 'phone-indicators.angola', flag: '🇦🇩', countryCode: 'AO' },
  { indicator: '+1264', countryTradKey: 'phone-indicators.anguilla', flag: '🇦🇮', countryCode: 'AI' },
  { indicator: '+1268', countryTradKey: 'phone-indicators.antigua-and-barbuda', flag: '🇦🇬', countryCode: 'AG' },
  { indicator: '+54', countryTradKey: 'phone-indicators.argentina', flag: '🇦🇷', countryCode: 'AR' },
  { indicator: '+374', countryTradKey: 'phone-indicators.armenia', flag: '🇦🇲', countryCode: 'AM' },
  { indicator: '+297', countryTradKey: 'phone-indicators.aruba', flag: '🇦🇼', countryCode: 'AW' },
  { indicator: '+61', countryTradKey: 'phone-indicators.australia', flag: '🇦🇺', countryCode: 'AU' },
  { indicator: '+43', countryTradKey: 'phone-indicators.austria', flag: '🇦🇹', countryCode: 'AT' },
  { indicator: '+994', countryTradKey: 'phone-indicators.azerbaijan', flag: '🇦🇿', countryCode: 'AZ' },
  { indicator: '+1242', countryTradKey: 'phone-indicators.bahamas', flag: '🇧🇸', countryCode: 'BS' },
  { indicator: '+973', countryTradKey: 'phone-indicators.bahrain', flag: '🇧🇭', countryCode: 'BH' },
  { indicator: '+880', countryTradKey: 'phone-indicators.bangladesh', flag: '🇧🇩', countryCode: 'BD' },
  { indicator: '+1246', countryTradKey: 'phone-indicators.barbados', flag: '🇧🇧', countryCode: 'BB' },
  { indicator: '+375', countryTradKey: 'phone-indicators.belarus', flag: '🇧🇾', countryCode: 'BY' },
  { indicator: '+32', countryTradKey: 'phone-indicators.belgium', flag: '🇧🇪', countryCode: 'BE' },
  { indicator: '+501', countryTradKey: 'phone-indicators.belize', flag: '🇧🇿', countryCode: 'BZ' },
  { indicator: '+229', countryTradKey: 'phone-indicators.benin', flag: '🇧🇯', countryCode: 'BJ' },
  { indicator: '+1441', countryTradKey: 'phone-indicators.bermuda', flag: '🇧🇲', countryCode: 'BM' },
  { indicator: '+975', countryTradKey: 'phone-indicators.bhutan', flag: '🇧🇹', countryCode: 'BT' },
  { indicator: '+591', countryTradKey: 'phone-indicators.bolivia', flag: '🇧🇴', countryCode: 'BO' },
  { indicator: '+387', countryTradKey: 'phone-indicators.bosnia-herzegovina', flag: '🇧🇦', countryCode: 'BA' },
  { indicator: '+267', countryTradKey: 'phone-indicators.botswana', flag: '🇧🇼', countryCode: 'BW' },
  { indicator: '+55', countryTradKey: 'phone-indicators.brazil', flag: '🇧🇷', countryCode: 'BR' },
  { indicator: '+673', countryTradKey: 'phone-indicators.brunei', flag: '🇧🇳', countryCode: 'BN' },
  { indicator: '+359', countryTradKey: 'phone-indicators.bulgaria', flag: '🇧🇬', countryCode: 'BG' },
  { indicator: '+226', countryTradKey: 'phone-indicators.burkina-faso', flag: '🇧🇫', countryCode: 'BF' },
  { indicator: '+257', countryTradKey: 'phone-indicators.burundi', flag: '🇧🇮', countryCode: 'BI' },
  { indicator: '+855', countryTradKey: 'phone-indicators.cambodia', flag: '🇰🇭', countryCode: 'KH' },
  { indicator: '+237', countryTradKey: 'phone-indicators.cameroon', flag: '🇨🇲', countryCode: 'CM' },
  { indicator: '+1', countryTradKey: 'phone-indicators.canada', flag: '🇨🇦', countryCode: 'CA' },
  { indicator: '+238', countryTradKey: 'phone-indicators.cape-verde-islands', flag: '🇨🇻', countryCode: 'CV' },
  { indicator: '+1345', countryTradKey: 'phone-indicators.cayman-islands', flag: '🇰🇾', countryCode: 'KY' },
  {
    indicator: '+236',
    countryTradKey: 'phone-indicators.central-african-republic',
    flag: '🇨🇫',
    countryCode: 'CF'
  },
  { indicator: '+56', countryTradKey: 'phone-indicators.chile', flag: '🇨🇱', countryCode: 'CL' },
  { indicator: '+86', countryTradKey: 'phone-indicators.china', flag: '🇨🇳', countryCode: 'CN' },
  { indicator: '+57', countryTradKey: 'phone-indicators.colombia', flag: '🇨🇴', countryCode: 'CO' },
  { indicator: '+269', countryTradKey: 'phone-indicators.comoros', flag: '🇰🇲', countryCode: 'KM' },
  { indicator: '+242', countryTradKey: 'phone-indicators.congo', flag: '🇨🇩', countryCode: 'CD' },
  { indicator: '+682', countryTradKey: 'phone-indicators.cook-islands', flag: '🇨🇰', countryCode: 'CK' },
  { indicator: '+506', countryTradKey: 'phone-indicators.costa-rica', flag: '🇨🇷', countryCode: 'CR' },
  { indicator: '+385', countryTradKey: 'phone-indicators.croatia', flag: '🇭🇷', countryCode: 'HR' },
  { indicator: '+53', countryTradKey: 'phone-indicators.cuba', flag: '🇨🇺', countryCode: 'CU' },
  { indicator: '+90392', countryTradKey: 'phone-indicators.cyprus-north', flag: '🇨🇾', countryCode: 'CY' },
  { indicator: '+357', countryTradKey: 'phone-indicators.cyprus-south', flag: '🇨🇾', countryCode: 'CY' },
  { indicator: '+42', countryTradKey: 'phone-indicators.czech-republic', flag: '🇨🇿', countryCode: 'CZ' },
  { indicator: '+45', countryTradKey: 'phone-indicators.denmark', flag: '🇩🇰', countryCode: 'DK' },
  { indicator: '+253', countryTradKey: 'phone-indicators.djibouti', flag: '🇩🇯', countryCode: 'DJ' },
  { indicator: '+1809', countryTradKey: 'phone-indicators.dominica', flag: '🇩🇲', countryCode: 'DM' },
  { indicator: '+1809', countryTradKey: 'phone-indicators.dominican-republic', flag: '🇩🇴', countryCode: 'DO' },
  { indicator: '+593', countryTradKey: 'phone-indicators.ecuador', flag: '🇪🇨', countryCode: 'EC' },
  { indicator: '+20', countryTradKey: 'phone-indicators.egypt', flag: '🇪🇬', countryCode: 'EG' },
  { indicator: '+503', countryTradKey: 'phone-indicators.el-salvador', flag: '🇸🇻', countryCode: 'SV' },
  { indicator: '+240', countryTradKey: 'phone-indicators.equatorial-guinea', flag: '🇬🇶', countryCode: 'GQ' },
  { indicator: '+291', countryTradKey: 'phone-indicators.eritrea', flag: '🇪🇷', countryCode: 'ER' },
  { indicator: '+372', countryTradKey: 'phone-indicators.estonia', flag: '🇪🇪', countryCode: 'EE' },
  { indicator: '+251', countryTradKey: 'phone-indicators.ethiopia', flag: '🇪🇹', countryCode: 'ET' },
  { indicator: '+500', countryTradKey: 'phone-indicators.falkland-islands', flag: '🇫🇰', countryCode: 'FK' },
  { indicator: '+298', countryTradKey: 'phone-indicators.faroe-islands', flag: '🇫🇴', countryCode: 'FO' },
  { indicator: '+679', countryTradKey: 'phone-indicators.fiji', flag: '🇫🇯', countryCode: 'FJ' },
  { indicator: '+358', countryTradKey: 'phone-indicators.finland', flag: '🇫🇮', countryCode: 'FI' },
  { indicator: '+33', countryTradKey: 'phone-indicators.france', flag: '🇫🇷', countryCode: 'FR' },
  { indicator: '+594', countryTradKey: 'phone-indicators.french-guyana', flag: '🇬🇫', countryCode: 'GF' },
  { indicator: '+689', countryTradKey: 'phone-indicators.french-polynesia', flag: '🇵🇫', countryCode: 'PF' },
  { indicator: '+241', countryTradKey: 'phone-indicators.gabon', flag: '🇬🇦', countryCode: 'GA' },
  { indicator: '+220', countryTradKey: 'phone-indicators.gambia', flag: '🇬🇲', countryCode: 'GM' },
  { indicator: '+7880', countryTradKey: 'phone-indicators.georgia', flag: '🇬🇪', countryCode: 'GE' },
  { indicator: '+49', countryTradKey: 'phone-indicators.germany', flag: '🇩🇪', countryCode: 'DE' },
  { indicator: '+233', countryTradKey: 'phone-indicators.ghana', flag: '🇬🇭', countryCode: 'GH' },
  { indicator: '+350', countryTradKey: 'phone-indicators.gibraltar', flag: '🇬🇮', countryCode: 'GI' },
  { indicator: '+30', countryTradKey: 'phone-indicators.greece', flag: '🇬🇷', countryCode: 'GR' },
  { indicator: '+299', countryTradKey: 'phone-indicators.greenland', flag: '🇬🇱', countryCode: 'GL' },
  { indicator: '+1473', countryTradKey: 'phone-indicators.grenada', flag: '🇬🇩', countryCode: 'GD' },
  { indicator: '+590', countryTradKey: 'phone-indicators.guadeloupe', flag: '🇬🇵', countryCode: 'GP' },
  { indicator: '+671', countryTradKey: 'phone-indicators.guam', flag: '🇬🇺', countryCode: 'GU' },
  { indicator: '+502', countryTradKey: 'phone-indicators.guatemala', flag: '🇬🇹', countryCode: 'GT' },
  { indicator: '+224', countryTradKey: 'phone-indicators.guinea', flag: '🇬🇳', countryCode: 'GN' },
  { indicator: '+245', countryTradKey: 'phone-indicators.guinea-bissau', flag: '🇬🇼', countryCode: 'GW' },
  { indicator: '+592', countryTradKey: 'phone-indicators.guyana', flag: '🇬🇾', countryCode: 'GY' },
  { indicator: '+509', countryTradKey: 'phone-indicators.haiti', flag: '🇭🇹', countryCode: 'HT' },
  { indicator: '+504', countryTradKey: 'phone-indicators.honduras', flag: '🇭🇳', countryCode: 'HN' },
  { indicator: '+852', countryTradKey: 'phone-indicators.hong-kong', flag: '🇭🇰', countryCode: 'HK' },
  { indicator: '+36', countryTradKey: 'phone-indicators.hungary', flag: '🇭🇺', countryCode: 'HU' },
  { indicator: '+354', countryTradKey: 'phone-indicators.iceland', flag: '🇮🇸', countryCode: 'IS' },
  { indicator: '+91', countryTradKey: 'phone-indicators.india', flag: '🇮🇳', countryCode: 'IN' },
  { indicator: '+62', countryTradKey: 'phone-indicators.indonesia', flag: '🇮🇩', countryCode: 'ID' },
  { indicator: '+98', countryTradKey: 'phone-indicators.iran', flag: '🇮🇷', countryCode: 'IR' },
  { indicator: '+964', countryTradKey: 'phone-indicators.iraq', flag: '🇮🇶', countryCode: 'IQ' },
  { indicator: '+353', countryTradKey: 'phone-indicators.ireland', flag: '🇮🇪', countryCode: 'IE' },
  { indicator: '+972', countryTradKey: 'phone-indicators.israel', flag: '🇮🇱', countryCode: 'IL' },
  { indicator: '+39', countryTradKey: 'phone-indicators.italy', flag: '🇮🇹', countryCode: 'IT' },
  { indicator: '+1876', countryTradKey: 'phone-indicators.jamaica', flag: '🇯🇲', countryCode: 'JM' },
  { indicator: '+81', countryTradKey: 'phone-indicators.japan', flag: '🇯🇵', countryCode: 'JP' },
  { indicator: '+962', countryTradKey: 'phone-indicators.jordan', flag: '🇯🇴', countryCode: 'JO' },
  { indicator: '+7', countryTradKey: 'phone-indicators.kazakhstan', flag: '🇰🇿', countryCode: 'KZ' },
  { indicator: '+254', countryTradKey: 'phone-indicators.kenya', flag: '🇰🇪', countryCode: 'KE' },
  { indicator: '+686', countryTradKey: 'phone-indicators.kiribati', flag: '🇰🇮', countryCode: 'KI' },
  { indicator: '+850', countryTradKey: 'phone-indicators.korea-north', flag: '🇰🇵', countryCode: 'KP' },
  { indicator: '+82', countryTradKey: 'phone-indicators.korea-south', flag: '🇰🇷', countryCode: 'KR' },
  { indicator: '+965', countryTradKey: 'phone-indicators.kuwait', flag: '🇰🇼', countryCode: 'KW' },
  { indicator: '+996', countryTradKey: 'phone-indicators.kyrgyzstan', flag: '🇰🇬', countryCode: 'KG' },
  { indicator: '+856', countryTradKey: 'phone-indicators.laos', flag: '🇱🇦', countryCode: 'LA' },
  { indicator: '+371', countryTradKey: 'phone-indicators.latvia', flag: '🇱🇻', countryCode: 'LV' },
  { indicator: '+961', countryTradKey: 'phone-indicators.lebanon', flag: '🇱🇧', countryCode: 'LB' },
  { indicator: '+266', countryTradKey: 'phone-indicators.lesotho', flag: '🇱🇸', countryCode: 'LS' },
  { indicator: '+231', countryTradKey: 'phone-indicators.liberia', flag: '🇱🇷', countryCode: 'LR' },
  { indicator: '+218', countryTradKey: 'phone-indicators.libya', flag: '🇱🇾', countryCode: 'LY' },
  { indicator: '+417', countryTradKey: 'phone-indicators.liechtenstein', flag: '🇱🇮', countryCode: 'LI' },
  { indicator: '+370', countryTradKey: 'phone-indicators.lithuania', flag: '🇱🇹', countryCode: 'LT' },
  { indicator: '+352', countryTradKey: 'phone-indicators.luxembourg', flag: '🇱🇺', countryCode: 'LU' },
  { indicator: '+853', countryTradKey: 'phone-indicators.macao', flag: '🇲🇴', countryCode: 'MO' },
  { indicator: '+389', countryTradKey: 'phone-indicators.macedonia', flag: '🇲🇰', countryCode: 'MK' },
  { indicator: '+261', countryTradKey: 'phone-indicators.madagascar', flag: '🇲🇬', countryCode: 'MG' },
  { indicator: '+265', countryTradKey: 'phone-indicators.malawi', flag: '🇲🇼', countryCode: 'MW' },
  { indicator: '+60', countryTradKey: 'phone-indicators.malaysia', flag: '🇲🇾', countryCode: 'MY' },
  { indicator: '+960', countryTradKey: 'phone-indicators.maldives', flag: '🇲🇻', countryCode: 'MV' },
  { indicator: '+223', countryTradKey: 'phone-indicators.mali', flag: '🇲🇱', countryCode: 'ML' },
  { indicator: '+356', countryTradKey: 'phone-indicators.malta', flag: '🇲🇹', countryCode: 'MT' },
  { indicator: '+692', countryTradKey: 'phone-indicators.marshall-islands', flag: '🇲🇭', countryCode: 'MH' },
  { indicator: '+596', countryTradKey: 'phone-indicators.martinique', flag: '🇲🇶', countryCode: 'MQ' },
  { indicator: '+222', countryTradKey: 'phone-indicators.mauritania', flag: '🇲🇷', countryCode: 'MR' },
  { indicator: '+269', countryTradKey: 'phone-indicators.mayotte', flag: '🇾🇹', countryCode: 'YT' },
  { indicator: '+52', countryTradKey: 'phone-indicators.mexico', flag: '🇲🇽', countryCode: 'MX' },
  { indicator: '+691', countryTradKey: 'phone-indicators.micronesia', flag: '🇫🇲', countryCode: 'FM' },
  { indicator: '+373', countryTradKey: 'phone-indicators.moldova', flag: '🇲🇩', countryCode: 'MD' },
  { indicator: '+377', countryTradKey: 'phone-indicators.monaco', flag: '🇲🇨', countryCode: 'MC' },
  { indicator: '+976', countryTradKey: 'phone-indicators.mongolia', flag: '🇲🇳', countryCode: 'MN' },
  { indicator: '+1664', countryTradKey: 'phone-indicators.montserrat', flag: '🇲🇸', countryCode: 'MS' },
  { indicator: '+212', countryTradKey: 'phone-indicators.morocco', flag: '🇲🇦', countryCode: 'MA' },
  { indicator: '+258', countryTradKey: 'phone-indicators.mozambique', flag: '🇲🇿', countryCode: 'MZ' },
  { indicator: '+95', countryTradKey: 'phone-indicators.myanmar', flag: '🇲🇲', countryCode: 'MM' },
  { indicator: '+264', countryTradKey: 'phone-indicators.namibia', flag: '🇳🇦', countryCode: 'NA' },
  { indicator: '+674', countryTradKey: 'phone-indicators.nauru', flag: '🇳🇷', countryCode: 'NR' },
  { indicator: '+977', countryTradKey: 'phone-indicators.nepal', flag: '🇳🇵', countryCode: 'NP' },
  { indicator: '+31', countryTradKey: 'phone-indicators.netherlands', flag: '🇳🇱', countryCode: 'NL' },
  { indicator: '+687', countryTradKey: 'phone-indicators.new-caledonia', flag: '🇳🇨', countryCode: 'NC' },
  { indicator: '+64', countryTradKey: 'phone-indicators.new-zealand', flag: '🇳🇿', countryCode: 'NZ' },
  { indicator: '+505', countryTradKey: 'phone-indicators.nicaragua', flag: '🇳🇮', countryCode: 'NI' },
  { indicator: '+227', countryTradKey: 'phone-indicators.niger', flag: '🇳🇪', countryCode: 'NE' },
  { indicator: '+234', countryTradKey: 'phone-indicators.nigeria', flag: '🇳🇬', countryCode: 'NG' },
  { indicator: '+683', countryTradKey: 'phone-indicators.niue', flag: '🇳🇺', countryCode: 'NU' },
  { indicator: '+672', countryTradKey: 'phone-indicators.norfolk-islands', flag: '🇳🇫', countryCode: 'NF' },
  { indicator: '+670', countryTradKey: 'phone-indicators.northern-marianas', flag: '🇲🇵', countryCode: 'MP' },
  { indicator: '+47', countryTradKey: 'phone-indicators.norway', flag: '🇳🇴', countryCode: 'NO' },
  { indicator: '+968', countryTradKey: 'phone-indicators.oman', flag: '🇴🇲', countryCode: 'OM' },
  { indicator: '+680', countryTradKey: 'phone-indicators.palau', flag: '🇵🇼', countryCode: 'PW' },
  { indicator: '+507', countryTradKey: 'phone-indicators.panama', flag: '🇵🇦', countryCode: 'PA' },
  { indicator: '+675', countryTradKey: 'phone-indicators.papua-new-guinea', flag: '🇵🇬', countryCode: 'PG' },
  { indicator: '+595', countryTradKey: 'phone-indicators.paraguay', flag: '🇵🇾', countryCode: 'PY' },
  { indicator: '+51', countryTradKey: 'phone-indicators.peru', flag: '🇵🇪', countryCode: 'PE' },
  { indicator: '+63', countryTradKey: 'phone-indicators.philippines', flag: '🇵🇭', countryCode: 'PH' },
  { indicator: '+48', countryTradKey: 'phone-indicators.poland', flag: '🇵🇱', countryCode: 'PL' },
  { indicator: '+351', countryTradKey: 'phone-indicators.portugal', flag: '🇵🇹', countryCode: 'PT' },
  { indicator: '+1787', countryTradKey: 'phone-indicators.puerto-rico', flag: '🇵🇷', countryCode: 'PR' },
  { indicator: '+974', countryTradKey: 'phone-indicators.qatar', flag: '🇶🇦', countryCode: 'QA' },
  { indicator: '+262', countryTradKey: 'phone-indicators.reunion', flag: '🇷🇪', countryCode: 'RE' },
  { indicator: '+40', countryTradKey: 'phone-indicators.romania', flag: '🇷🇴', countryCode: 'RO' },
  { indicator: '+7', countryTradKey: 'phone-indicators.russia', flag: '🇷🇺', countryCode: 'RU' },
  { indicator: '+250', countryTradKey: 'phone-indicators.rwanda', flag: '🇷🇼', countryCode: 'RW' },
  { indicator: '+378', countryTradKey: 'phone-indicators.san-marino', flag: '🇸🇲', countryCode: 'SM' },
  { indicator: '+239', countryTradKey: 'phone-indicators.sao-tome-and-principe', flag: '🇸🇹', countryCode: 'ST' },
  { indicator: '+966', countryTradKey: 'phone-indicators.saudi-arabia', flag: '🇸🇦', countryCode: 'SA' },
  { indicator: '+221', countryTradKey: 'phone-indicators.senegal', flag: '🇸🇳', countryCode: 'SN' },
  { indicator: '+381', countryTradKey: 'phone-indicators.serbia', flag: '🇷🇸', countryCode: 'RS' },
  { indicator: '+248', countryTradKey: 'phone-indicators.seychelles', flag: '🇸🇨', countryCode: 'SC' },
  { indicator: '+232', countryTradKey: 'phone-indicators.sierra-leone', flag: '🇸🇱', countryCode: 'SL' },
  { indicator: '+65', countryTradKey: 'phone-indicators.singapore', flag: '🇸🇬', countryCode: 'SG' },
  { indicator: '+421', countryTradKey: 'phone-indicators.slovak-republic', flag: '🇸🇰', countryCode: 'SK' },
  { indicator: '+386', countryTradKey: 'phone-indicators.slovenia', flag: '🇸🇮', countryCode: 'SI' },
  { indicator: '+677', countryTradKey: 'phone-indicators.solomon-islands', flag: '🇸🇧', countryCode: 'SB' },
  { indicator: '+252', countryTradKey: 'phone-indicators.somalia', flag: '🇸🇧', countryCode: 'SO' },
  { indicator: '+27', countryTradKey: 'phone-indicators.south-africa', flag: '🇿🇦', countryCode: 'ZA' },
  { indicator: '+34', countryTradKey: 'phone-indicators.spain', flag: '🇪🇸', countryCode: 'ES' },
  { indicator: '+94', countryTradKey: 'phone-indicators.sri-lanka', flag: '🇱🇰', countryCode: 'LK' },
  { indicator: '+290', countryTradKey: 'phone-indicators.st-helena', flag: '🇸🇭', countryCode: 'SH' },
  { indicator: '+1869', countryTradKey: 'phone-indicators.st-kitts', flag: '🇰🇳', countryCode: 'KN' },
  { indicator: '+1758', countryTradKey: 'phone-indicators.st-lucia', flag: '🇱🇨', countryCode: 'LC' },
  { indicator: '+249', countryTradKey: 'phone-indicators.sudan', flag: '🇸🇩', countryCode: 'SD' },
  { indicator: '+597', countryTradKey: 'phone-indicators.suriname', flag: '🇸🇷', countryCode: 'SR' },
  { indicator: '+268', countryTradKey: 'phone-indicators.swaziland', flag: '🇸🇿', countryCode: 'SZ' },
  { indicator: '+46', countryTradKey: 'phone-indicators.sweden', flag: '🇸🇪', countryCode: 'SE' },
  { indicator: '+41', countryTradKey: 'phone-indicators.switzerland', flag: '🇨🇭', countryCode: 'CH' },
  { indicator: '+963', countryTradKey: 'phone-indicators.syria', flag: '🇸🇾', countryCode: 'SY' },
  { indicator: '+886', countryTradKey: 'phone-indicators.taiwan', flag: '🇹🇼', countryCode: 'TW' },
  { indicator: '+7', countryTradKey: 'phone-indicators.tajikstan', flag: '🇹🇯', countryCode: 'TJ' },
  { indicator: '+66', countryTradKey: 'phone-indicators.thailand', flag: '🇹🇭', countryCode: 'TH' },
  { indicator: '+228', countryTradKey: 'phone-indicators.togo', flag: '🇹🇬', countryCode: 'TG' },
  { indicator: '+676', countryTradKey: 'phone-indicators.tonga', flag: '🇹🇴', countryCode: 'TO' },
  { indicator: '+1868', countryTradKey: 'phone-indicators.trinidad-and-tobago', flag: '🇹🇹', countryCode: 'TT' },
  { indicator: '+216', countryTradKey: 'phone-indicators.tunisia', flag: '🇹🇳', countryCode: 'TN' },
  { indicator: '+90', countryTradKey: 'phone-indicators.turkey', flag: '🇹🇷', countryCode: 'TR' },
  { indicator: '+7', countryTradKey: 'phone-indicators.turkmenistan', flag: '🇹🇲', countryCode: 'TM' },
  { indicator: '+993', countryTradKey: 'phone-indicators.turkmenistan', flag: '🇹🇲', countryCode: 'TM' },
  {
    indicator: '+1649',
    countryTradKey: 'phone-indicators.turks-and-caicos-islands',
    flag: '🇹🇨',
    countryCode: 'TC'
  },
  { indicator: '+688', countryTradKey: 'phone-indicators.tuvalu', flag: '🇹🇻', countryCode: 'TV' },
  { indicator: '+256', countryTradKey: 'phone-indicators.uganda', flag: '🇺🇬', countryCode: 'UG' },
  { indicator: '+44', countryTradKey: 'phone-indicators.uk', flag: '🇬🇧', countryCode: 'GB' },
  { indicator: '+380', countryTradKey: 'phone-indicators.ukraine', flag: '🇺🇦', countryCode: 'UA' },
  { indicator: '+971', countryTradKey: 'phone-indicators.united-arab-emirates', flag: '🇦🇪', countryCode: 'AE' },
  { indicator: '+598', countryTradKey: 'phone-indicators.uruguay', flag: '🇺🇾', countryCode: 'UY' },
  { indicator: '+1', countryTradKey: 'phone-indicators.usa', flag: '🇺🇸', countryCode: 'US' },
  { indicator: '+7', countryTradKey: 'phone-indicators.uzbekistan', flag: '🇺🇿', countryCode: 'UZ' },
  { indicator: '+678', countryTradKey: 'phone-indicators.vanuatu', flag: '🇻🇺', countryCode: 'VU' },
  { indicator: '+379', countryTradKey: 'phone-indicators.vatican-city', flag: '🇻🇦', countryCode: 'VA' },
  { indicator: '+58', countryTradKey: 'phone-indicators.venezuela', flag: '🇻🇪', countryCode: 'VE' },
  { indicator: '+84', countryTradKey: 'phone-indicators.vietnam', flag: '🇻🇳', countryCode: 'VN' },
  { indicator: '+84', countryTradKey: 'phone-indicators.virgin-islands-british', flag: '🇻🇬', countryCode: 'VG' },
  { indicator: '+84', countryTradKey: 'phone-indicators.virgin-islands-us', flag: '🇻🇮', countryCode: 'VI' },
  { indicator: '+681', countryTradKey: 'phone-indicators.wallis-and-futuna', flag: '🇼🇫', countryCode: 'WF' },
  { indicator: '+969', countryTradKey: 'phone-indicators.yemen-north', flag: '🇾🇪', countryCode: 'YE' },
  { indicator: '+967', countryTradKey: 'phone-indicators.yemen-south', flag: '🇾🇪', countryCode: 'YE' },
  { indicator: '+260', countryTradKey: 'phone-indicators.zambia', flag: '🇿🇲', countryCode: 'ZM' },
  { indicator: '+263', countryTradKey: 'phone-indicators.zimbabwe', flag: '🇿🇼', countryCode: 'ZW' }
]
