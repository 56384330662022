export const useRandomId = (): { generate: () => string } => {
  const generate = () => {
    const idParts = []

    const generateRandomPart = () => {
      return Number((Math.random() * 10 ** 16).toFixed(0)).toString(36)
    }

    idParts.push(generateRandomPart())
    idParts.push(generateRandomPart())
    idParts.push(generateRandomPart())
    idParts.push(Date.now().toString(36))

    return idParts.join('-')
  }
  return {
    generate
  }
}
