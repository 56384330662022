import type { ICartRepository } from '@contexts/cart/domain/cart-repository.interface'
import type { CartId, ICart } from '@contexts/cart/domain/cart.model'

interface IRemoveLineParam {
  cartRepository: ICartRepository
}

export class RemoveLine {
  public readonly cartRepository: ICartRepository

  constructor({ cartRepository }: IRemoveLineParam) {
    this.cartRepository = cartRepository
  }

  async execute(cartId: CartId, lineId: string): Promise<ICart> {
    return this.cartRepository.removeLine(cartId, lineId)
  }
}
