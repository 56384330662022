import type { Address } from '@/domain/address'
import type { IUserRepository } from '../infrastructure/interfaces/i-user.repository'

export class PostAddress {
  constructor(public readonly userRepository: IUserRepository) {}

  async execute(address: Address): Promise<Address> {
    return this.userRepository.postAddress(address)
  }
}
