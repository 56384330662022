import type { EntryCollection } from 'contentful'
import type { IContentfulClient } from '@infrastructure/contentful'
import type { TypeCommonConfigSkeleton } from '@invivodf/contentful-types-retail'

export class ConfigDataSource {
  constructor(public contentfulClient: IContentfulClient, public contentfulContentType: string) {}

  async fetchConfig(locale: string): Promise<EntryCollection<TypeCommonConfigSkeleton> | null> {
    const options = {
      content_type: this.contentfulContentType,
      include: 3
    }
    return this.contentfulClient.fetchCollection(options, locale)
  }
}
