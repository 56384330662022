import type { LinkLoyalty } from '@contexts/user/domain/link-loyalty.model'
import type { IUserRepository } from '../infrastructure/interfaces/i-user.repository'

export class UpdateUserLoyaltyCard {
  constructor(public readonly userRepository: IUserRepository) {}

  async execute(cardNumber: number, postalCode?: string): Promise<LinkLoyalty> {
    return this.userRepository.linkLoyaltyCard(cardNumber, postalCode)
  }
}
