import type { UpdateLineOfferQuery } from '@invivodf/module-cart-retail-interfaces'
import type { ICartRepository } from '../domain/cart-repository.interface'
import type { CartId, ICart } from '../domain/cart.model'

interface IUpdateLineOffer {
  cartRepository: ICartRepository
}

export class UpdateLineOffer {
  public readonly cartRepository: ICartRepository

  constructor({ cartRepository }: IUpdateLineOffer) {
    this.cartRepository = cartRepository
  }

  async execute(cartId: CartId, lineId: string, params: UpdateLineOfferQuery): Promise<ICart> {
    return this.cartRepository.updateLineOffer(cartId, lineId, params)
  }
}
