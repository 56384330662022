/* eslint-disable no-console */
import { tryUseNuxtApp } from '#imports'
import type { SeverityLevel } from '@sentry/types'

type Logger = {
  error: (message: string, error: Error | unknown) => void
  warn: (message: string) => void
  info: (message: string) => void
  debug: (message: string) => void
}
/**
 * @deprecated to migrate in composable
 */
function logMessage(level: SeverityLevel, message: string): void {
  const { $sentry: sentry } = tryUseNuxtApp() ?? {}
  if (process.client) sentry?.captureMessage(message, level)
}
/**
 * @deprecated to migrate in composable
 */
function logError(message: string, error: Error): void {
  const { $sentry: sentry } = tryUseNuxtApp() ?? {}
  if (process.client) sentry?.captureException(error, { extra: { message } })
  console.error(message)
}
export function loggerFactory(): Logger {
  const isDev = process.env.NODE_ENV === 'development'
  return {
    error: isDev ? console.error : logError,
    warn: isDev ? console.warn : (message) => logMessage('warning' as SeverityLevel, message),
    info: isDev ? console.info : (message) => logMessage('info' as SeverityLevel, message),
    debug: isDev ? console.debug : (message) => logMessage('debug' as SeverityLevel, message)
  }
}

export default loggerFactory()
