import type { IStoreLocatorRepository } from '@contexts/store-locator/domain/store-locator-repository.interface'
import type { ICurrentStore } from '@/domain/current-store/current-store.interface'
import type { IUseCookie } from '@/application/composables/cookie'

export class GetCurrentStore {
  constructor(public readonly storeLocatorRepository: IStoreLocatorRepository) {}

  async execute(
    isUserConnected: boolean,
    updateCookie: IUseCookie,
    storeCookie: IUseCookie
  ): Promise<ICurrentStore | null> {
    const result = await this.storeLocatorRepository.getCurrentStore(isUserConnected, updateCookie, storeCookie)
    const isStoreExpired = !result.expirationDate || result.expirationDate < new Date()
    if (isStoreExpired && result.currentStore)
      return this.storeLocatorRepository.refreshCurrentStore(result.currentStore, isUserConnected, storeCookie)
    return result.currentStore
  }
}
