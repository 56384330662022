import type { LocaleIso, LocaleCode } from '@application/composables/locale'

const WEB_STORE_GROUP_ID = 'web'

export class AlgoliaIndexMapper {
  constructor(
    public readonly productsIndexName: string,
    public readonly categoriesIndexName: string,
    public readonly articlesIndexName: string
  ) {}

  getProductsIndexName(localeIso: LocaleIso, storeGroupId?: string): string {
    const groupIdIndex = storeGroupId && storeGroupId !== WEB_STORE_GROUP_ID ? `.${storeGroupId}` : ''
    return `${this.productsIndexName}${groupIdIndex}${AlgoliaIndexMapper.getLocaleIndex(localeIso)}`
  }

  getCategoriesIndexName(localeIso: LocaleIso): string {
    return `${this.categoriesIndexName}${AlgoliaIndexMapper.getLocaleIndex(localeIso)}`
  }

  getArticlesIndexName(locale: LocaleCode): string {
    return `${this.articlesIndexName}${AlgoliaIndexMapper.getLocaleIndex(locale)}`
  }

  recommendProductsIndexName(): string {
    return this.productsIndexName
  }

  private static getLocaleIndex(locale: LocaleIso | LocaleCode): string {
    return (locale as LocaleIso) !== 'fr-FR' && (locale as LocaleCode) !== 'fr' ? `.${locale}` : ''
  }
}
