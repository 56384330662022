import type { IRequest } from '@application/composables/request'
import type { IIzanamiDatasource } from '@domain/izanami/izanami-repository.interface'
import type { IIzanamiItem } from '@domain/izanami/izanami-item.interface'

export class IzanamiDatasource implements IIzanamiDatasource {
  constructor(public requestService: IRequest, public izanamiUrl: string | null) {}

  async isEnabled(featureName: string): Promise<boolean> {
    if (!this.izanamiUrl || !featureName) return Promise.resolve(false)

    const url = `${this.izanamiUrl}/${featureName}`
    const result = await this.requestService.get<IIzanamiItem>(url).catch(() => Promise.resolve(null))

    return !!result && typeof result === 'object' && result.enabled
  }
}
