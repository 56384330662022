import type { ConfigDataSource } from '@contexts/config/infrastructure/data-sources/config.data-source'
import type { IConfigRepository } from '@contexts/config/domain/config-repository.interface'
import type { CommonConfig } from '@contexts/config/domain/config.model'
import type { Cache } from '@infrastructure/cache/utils/cache.interface'
import type { EntryCollection } from 'contentful'
import type { TypeCommonConfigSkeleton } from '@invivodf/contentful-types-retail'
import { CommonConfigMapper } from './data-sources/mappers/common-config.mapper'

export class ConfigRepository implements IConfigRepository {
  constructor(private readonly configDataSource: ConfigDataSource, private readonly cacheInstance: Cache) {}

  async findConfig(locale: string): Promise<CommonConfig | null> {
    const configCacheSpace = `configContentFul-localized-${locale}`
    let configDTOContentFul = await this.cacheInstance.get<EntryCollection<TypeCommonConfigSkeleton>>(configCacheSpace)

    if (!configDTOContentFul) {
      configDTOContentFul = await this.configDataSource.fetchConfig(locale)

      if (configDTOContentFul) {
        this.cacheInstance.set(configCacheSpace, configDTOContentFul)
      }
    }

    return CommonConfigMapper.extract(configDTOContentFul)
  }
}
