import type { AutocompleteAddress } from '@invivoretail/module-store-locator-sdk'
import type { IAddressAutocompleteRepository } from '@infrastructure/address-autocomplete'

export class GetAddressAutocomplete {
  constructor(public readonly repository: IAddressAutocompleteRepository) {}

  async execute(research: string, locale: 'fr-FR' | 'es-ES'): Promise<AutocompleteAddress[]> {
    return this.repository.fetchAddressAutocomplete(research, locale)
  }
}
