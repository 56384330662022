import type { Cart, CartWithErrors, Line, Subtotal, ValidationError } from '@invivodf/module-cart-retail-interfaces'
import { ValidationErrorKeys } from '@invivodf/module-cart-retail-interfaces'
import type { ICart } from '@contexts/cart/domain/cart.model'
import type { ILine } from '@contexts/cart/domain/line.model'
import type { CartErrorData, CartWithErrorsData } from '@contexts/cart/domain/cart-error.model'
import { CartErrorKeys } from '@contexts/cart/domain/cart-error.model'
import type { ISubtotal } from '../domain/subtotal.model'

const convertNumberToString = (digit: number, number?: number) => {
  return number?.toFixed(digit) || '0.'.padEnd(digit + 2, '0')
}

const toISubtotal = (subtotal?: Subtotal): ISubtotal => {
  return Object.entries(subtotal || {}).reduce(
    (acc, [key, value]) => {
      return { ...acc, [key]: convertNumberToString(2, value) }
    },
    {
      totalDeee: '0.00',
      totalFurniture: '0.00',
      totalVat: '0.00',
      estimatedShippingCost: '0.00',
      totalDiscount: '0.00',
      totalPriceWithoutDiscount: '0.00',
      totalPriceWithDiscount: '0.00',
      totalPriceWithDiscountAndShipping: '0.00'
    }
  )
}

const toIline = (line: Line): ILine => {
  return {
    ...line,
    linePrice: {
      unitPriceWithVAT: convertNumberToString(2, line.linePrice.unitPriceWithVAT),
      unitPriceWithVATAndDiscount: convertNumberToString(2, line.linePrice.unitPriceWithVATAndDiscount)
    },
    total: convertNumberToString(2, line.total)
  }
}

export const toICart = (cart: Cart): ICart => {
  return {
    ...cart,
    subtotal: toISubtotal(cart.subtotal),
    lines: cart.lines.map(toIline)
  }
}

function mapValidationErrorKeyToCartErrorKey(validationErrorKey: ValidationErrorKeys): CartErrorKeys {
  switch (validationErrorKey) {
    case ValidationErrorKeys.CartErrorsRestore:
      return CartErrorKeys.CART_RESTORE_ERROR_KEY
    case ValidationErrorKeys.InsufficientStockErrors:
      return CartErrorKeys.STOCK_INSUFFICIENT_ERROR_KEY
    case ValidationErrorKeys.StockErrorsMissing:
      return CartErrorKeys.STOCK_MISSING_ERROR_KEY
    case ValidationErrorKeys.OfferPriceErrors:
      return CartErrorKeys.PRICE_CHANGED_ERROR_KEY
    case ValidationErrorKeys.LogisticOffersErrorsMissing:
      return CartErrorKeys.LOGISTIC_ROUTE_INVALID_ERROR_KEY
    case ValidationErrorKeys.DiscountErrorsAmount:
      return CartErrorKeys.DISCOUNT_ERRORS_AMOUNT
    case ValidationErrorKeys.DiscountErrorsMissing:
      return CartErrorKeys.DISCOUNT_ERRORS_MISSING
    case ValidationErrorKeys.OfferErrorsMissing:
      return CartErrorKeys.OFFER_ERRORS_MISSING
    case ValidationErrorKeys.LineItemErrorsMissing:
      return CartErrorKeys.LINE_MISSING
    default:
      return CartErrorKeys.Unknown
  }
}

const toCartErrorData = (validationError: ValidationError): CartErrorData<CartErrorKeys> => {
  return {
    key: mapValidationErrorKeyToCartErrorKey(validationError.key),
    message: validationError.message,
    details: validationError.details
  }
}

export const toICartWithErrors = (cartWithErrors: CartWithErrors): CartWithErrorsData<CartErrorKeys> => {
  const { cart, warnings, errors, alerts } = cartWithErrors
  return {
    cart: toICart(cart),
    alerts: alerts.map(toCartErrorData),
    warnings: warnings.map(toCartErrorData),
    errors: errors?.map(toCartErrorData)
  }
}
