import { useConfig } from '@application/composables/config'
import {
  addLine,
  createCheckout,
  getCart,
  removeLine,
  updateLineOffer,
  updateLineQuantity,
  updateStore
} from '@contexts/cart'
import { isCartWithErrors } from '@contexts/cart/domain/cart-error.model'
import type { ICart, ICartCheckout } from '@contexts/cart/domain/cart.model'
import type { ILineOfferReference } from '@contexts/cart/domain/line-offer.model'
import type { ILineProductId } from '@contexts/cart/domain/line-product.model'
import { toICart } from '@contexts/cart/infrastructure/cart.mapper'
import { type ProductSheetOffer } from '@contexts/product/domain/product-sheet/product-sheet-offer.model'
import type { Cart, RetailDeliveryType } from '@invivodf/module-cart-retail-interfaces'
import type { ActionContext, ActionTree } from 'vuex'
import type { ICurrentStore } from '@/domain/current-store/current-store.interface'
import { useNuxtApp } from '#imports'
import type { IRootState } from '../state.interface'
import { cartTypes } from './constants'
import type { ICartState } from './interface'

type AddLineProps = {
  quantity: number
  deliveryType: RetailDeliveryType
  offer: ProductSheetOffer
}

type UpdateLineQuantityProps = {
  lineId: string
  quantity: number
}

type UpdateLineLogisticRouteProps = {
  lineId: string
  productId: ILineProductId
  deliveryType: RetailDeliveryType
  offerReference: ILineOfferReference
}

const cartActions: ActionTree<ICartState, IRootState> = {
  async refreshCart(context: ActionContext<ICartState, IRootState>) {
    let cart: ICart
    const cartId = localStorage.getItem('cartId')
    const { $i18n: i18n } = useNuxtApp()
    const { getWebstore } = useConfig()
    const shop = context.rootState.shop.current || getWebstore(i18n.locale.value)
    try {
      cart = await getCart.execute({
        ...(cartId && { cartId }),
        storeId: shop.id
      })
    } catch (e) {
      if (isCartWithErrors(e)) {
        cart = e.data.cart
        context.commit(cartTypes.SET_CART, cart)
        localStorage.setItem('cartId', cart.id)
      }
      throw e
    }

    context.commit(cartTypes.SET_CART, cart)
    localStorage.setItem('cartId', cart.id)
  },
  async addLine(context: ActionContext<ICartState, IRootState>, data: AddLineProps) {
    const cartId = localStorage.getItem('cartId')
    const { $i18n: i18n } = useNuxtApp()
    const { getWebstore } = useConfig()
    const shop = context.rootState.shop.current || getWebstore(i18n.locale.value)

    const cart = await addLine.execute(cartId, {
      quantity: data.quantity,
      offerReference: data.offer.reference,
      deliveryType: data.deliveryType,
      storeId: shop.id
    })

    localStorage.setItem('cartId', cart.id)
  },
  async removeLine(context: ActionContext<ICartState, IRootState>, lineId: string) {
    try {
      const cartId = localStorage.getItem('cartId')
      const cart = await removeLine.execute(cartId!, lineId)

      context.commit(cartTypes.SET_CART, cart)
    } catch (e) {
      if (isCartWithErrors(e)) {
        const { cart } = e.data
        context.commit(cartTypes.SET_CART, cart)
      }
      throw e
    }
  },
  async updateLineQuantity(context: ActionContext<ICartState, IRootState>, data: UpdateLineQuantityProps) {
    const { $i18n: i18n } = useNuxtApp()
    const { getWebstore } = useConfig()
    const shop = context.rootState.shop.current || getWebstore(i18n.locale.value)

    try {
      const cart = await updateLineQuantity.execute({
        cartId: context.state.id,
        lineId: data.lineId,
        quantity: data.quantity,
        storeId: shop.id
      })
      context.commit(cartTypes.SET_CART, cart)
    } catch (e) {
      if (isCartWithErrors(e)) {
        const { cart } = e.data
        context.commit(cartTypes.SET_CART, cart)
        localStorage.setItem('cartId', cart.id)
      }
      throw e
    }
  },
  async updateLineLogisticRoute(context: ActionContext<ICartState, IRootState>, data: UpdateLineLogisticRouteProps) {
    const { $i18n: i18n } = useNuxtApp()
    const { getWebstore } = useConfig()
    const shop = context.rootState.shop.current || getWebstore(i18n.locale.value)
    try {
      const cart = await updateLineOffer.execute(context.state.id, data.lineId, {
        storeId: shop.id,
        productId: data.productId,
        deliveryType: data.deliveryType,
        offerReference: data.offerReference
      })

      context.commit(cartTypes.SET_CART, cart)
    } catch (e) {
      if (isCartWithErrors(e)) {
        const { cart } = e.data
        context.commit(cartTypes.SET_CART, cart)
      }
      throw e
    }
  },
  async updateStore(context: ActionContext<ICartState, IRootState>, store: ICurrentStore | null) {
    if (store && context.state.id) {
      let cart: ICart

      try {
        cart = await updateStore.execute(store, context.state.id)
      } catch (e) {
        if (isCartWithErrors(e)) {
          cart = toICart(e.data.cart as unknown as Cart)
          context.commit(cartTypes.SET_CART, cart)
        }
        throw e
      }

      context.commit(cartTypes.SET_CART, cart)
    }
  },
  async createCheckout(context: ActionContext<ICartState, IRootState>) {
    const { $i18n: i18n } = useNuxtApp()
    const { getWebstore } = useConfig()
    const shop = context.rootState.shop.current || getWebstore(i18n.locale.value)

    async function createCheckoutFunction() {
      try {
        return createCheckout.execute(context.state.id, shop.id)
      } catch (e) {
        if (isCartWithErrors(e)) {
          const { cart } = e.data
          context.commit(cartTypes.SET_CART, cart)
        }
        throw e
      }
    }

    const cartCheckout: ICartCheckout | undefined = await createCheckoutFunction()

    localStorage.setItem('checkoutId', cartCheckout.id)
  }
}
export default cartActions
