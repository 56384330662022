import { defineNuxtPlugin, ref, reloadNuxtApp, useRouter } from '#imports'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import type { LocaleCode } from '../composables/locale'

export interface PathState {
  [LocaleCode.FR]: string
  [LocaleCode.ES]: string
}

export default defineNuxtPlugin(() => {
  const pathState = ref<PathState>({} as PathState)
  const router = useRouter()

  const switchToLocalePath = async (locale: LocaleCode) => {
    reloadNuxtApp({
      path: pathState.value[locale]
    })
  }

  const updatePathState = (pState: PathState) => {
    pathState.value = pState
  }

  router.beforeEach((to: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) => {
    updatePathState({} as PathState)

    // ENSBUG-1262: Prevent search page from loading without a query
    if ((to.name as string)?.includes('search') && !to.query.q) {
      return next(false)
    }

    return next()
  })

  return {
    provide: {
      pathState: {
        switchToLocalePath,
        updatePathState,
        state: pathState
      }
    }
  }
})
