import type { IAuthenticationRepository } from '../domain/authentication-repository.interface'
import type { ILoginParamsDTO } from '../domain/login-dto.model'

export class RedirectionToLoginPage {
  constructor(private readonly authenticationRepository: IAuthenticationRepository) {}

  async execute(params: ILoginParamsDTO): Promise<void> {
    return this.authenticationRepository.loginWithRedirect(params)
  }
}
