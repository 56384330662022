import type { IConfigState } from '@application/store/config/interface'
import type { MutationTree } from 'vuex'
import { configTypes } from '@application/store/config/constants'

const configMutations: MutationTree<IConfigState> = {
  [configTypes.SET_CONFIG]: (state: IConfigState, config: IConfigState) => {
    Object.assign(state, config)
  },

  [configTypes.SET_INNER_WIDTH]: (state: IConfigState, innerWidth: number) => {
    state.innerWidth = innerWidth
  }
}

export default configMutations
