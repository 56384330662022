import type { ImageDataset } from '@application/types/image-dataset'
import type { IProductDiscount, IProductMeta } from '@domain/product/product.interface'

export class ProductRecommend {
  constructor(
    public id: string,
    public categories: string[],
    public image: ImageDataset | null,
    public isIndicativePrice: boolean,
    public name: string,
    public price: number,
    public slug: string,
    public brand?: string,
    public discount?: IProductDiscount,
    public meta?: IProductMeta
  ) {}

  toJSON() {
    return {
      ...this
    }
  }
}
