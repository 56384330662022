import type { Store, StoreDetails } from '@invivoretail/module-store-locator-sdk'

import type { IStoreLocatorRepository } from '@contexts/store-locator/domain/store-locator-repository.interface'

export class IsEnrolledStore {
  constructor(public readonly storeLocatorRepository: IStoreLocatorRepository) {}

  execute(store: Store | StoreDetails): boolean {
    return this.storeLocatorRepository.isEnrolledStore(store)
  }
}
