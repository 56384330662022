const types = {
  ARTICLE: 'article',
  ARTICLE_CATEGORY: 'articleCategory',
  ARTICLE_COLUMNS_IMAGE: 'articleColumnsImage',
  ARTICLE_COLUMNS_TEXT: 'articleColumnsText',
  ARTICLE_ACCORDION: 'articleAccordion',
  ARTICLE_INLINE_TABLE: 'articleInlineTable',

  BRAND: 'brand',

  HOME: 'home',

  LANDING: 'landing',

  TERMS: 'terms',

  PROMOTION: 'promotion',

  FOOTER: 'footer',
  FOOTER_INFO_BLOCK: 'footerInfoBlock',
  FOOTER_INFO_HIGHLIGHTED: 'footerInfoHighlighted',
  FOOTER_INFO_SOCIAL: 'footerInfoSocial',
  FOOTER_INFO_CONTACT: 'footerInfoContact',

  LINK_ITEM: 'linkItem',

  COMMON_CONFIG: 'commonConfig',
  COMMON_SPOTLIGHT: 'commonSpotLight',
  COMMON_SEO: 'commonSeo',
  COMMON_ADAPTIVE_IMAGE: 'commonAdaptativeImage',
  COMMON_SLIDESHOW: 'commonSlideshow',
  COMMON_CARD: 'commonCard',
  COMMON_LIST_ITEM: 'commonListItem',
  COMMON_LIST_LINK: 'commonListLink',
  COMMON_RESSOURCE: 'commonRessource',
  CONDITIONAL_SPOTLIGHT: 'conditionalSpotlight',

  PRODUCT_CATEGORY_SEO: 'productCategorySeo',

  RECOMMENDATION: 'recommendation'
}

export const contentfulContentTypes: typeof types = types
