export const USER_ACTIONS = Object.freeze({
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_USER_CONNECTION: 'SET_USER_CONNECTION',
  SET_USER_LOADED: 'SET_USER_LOADED'
})

export enum EUserStatus {
  NOT_CONNECTED = 'NOT_CONNECTED',
  CONNECTED = 'CONNECTED',
  FID_CONNECTED = 'FID_CONNECTED'
}
