import type { IRootState } from '@application/store/state.interface'
import type { ActionContext, ActionTree } from 'vuex'
import type { IAxeptioState } from '@application/store/axeptio/interface'
import { axeptioTypes } from '@application/store/axeptio/constants'

const axeptioActions: ActionTree<IAxeptioState, IRootState> = {
  async setReady(context: ActionContext<IAxeptioState, IRootState>, isReady: boolean) {
    context.commit(axeptioTypes.SET_READY, isReady)

    return context.state
  }
}

export default axeptioActions
