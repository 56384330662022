import type { CommonAdaptativeImageItem } from '@/domain/contentful/common-adaptative-image-item.model'

export class CategoryConfig {
  constructor(
    public title: string,
    public metaDescription: string,
    public brands: CommonAdaptativeImageItem[] | null
  ) {}

  toJSON(): CategoryConfig {
    return { ...this }
  }
}
