import type { Optins } from '../../domain/optins.model'
import type { IUserRepository } from '../../infrastructure/interfaces/i-user.repository'

export class UpdateOptins {
  constructor(public readonly userRepository: IUserRepository) {}

  async execute(newOptins: Optins): Promise<Optins | null> {
    return this.userRepository.updateOptins(newOptins)
  }
}
