import type { ICartRepository } from '@contexts/cart/domain/cart-repository.interface'
import type { ICart } from '@contexts/cart/domain/cart.model'
import type { ICurrentStore } from '@/domain/current-store/current-store.interface'

interface IUpdateStore {
  cartRepository: ICartRepository
}

export class UpdateStore {
  public readonly cartRepository: ICartRepository

  constructor({ cartRepository }: IUpdateStore) {
    this.cartRepository = cartRepository
  }

  async execute(store: ICurrentStore, cartId: string): Promise<ICart> {
    return this.cartRepository.updateStore(store, cartId)
  }
}
