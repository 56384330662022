import type { IStoreLocatorRepository } from '@contexts/store-locator/domain/store-locator-repository.interface'
import type { IStoreCoordinates } from '../../domain/coordinates.interface'
import type { StoreOffers } from '../../domain/store-offers.interface'

export class GetStoresWithOffersUsecase {
  constructor(public readonly storeLocatorRepository: IStoreLocatorRepository) {}

  async execute(coordinates: IStoreCoordinates, productId: string): Promise<StoreOffers[]> {
    return this.storeLocatorRepository.fetchStoresWithOffers(coordinates, productId)
  }
}
