import type { Address } from '@/domain/address'
import type { IUserRepository } from '../infrastructure/interfaces/i-user.repository'

export class ToggleIsDefaultAddress {
  constructor(private readonly userRepository: IUserRepository) {}

  async execute(addressId: string): Promise<Address> {
    return this.userRepository.toggleIsDefaultAddress(addressId)
  }
}
