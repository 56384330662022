import type { AddLineItemQueryV2, Cart, UpdateLineOfferQuery } from '@invivodf/module-cart-retail-interfaces'
import type { CartId, ICartCheckout } from '@contexts/cart/domain/cart.model'
import type { IRequest } from '@application/composables/request'
import type { ICurrentStore } from '@/domain/current-store/current-store.interface'
import type { IUpdateLineQuantityQuery } from '../../domain/update-line-quantity-query.interface'

interface IFindOneParams {
  storeId: string
  storeSlugId: string
  cartId?: string
}
export class CartDataSource {
  constructor(public requestService: IRequest, public getCartApiUrl: (path?: string) => string) {}

  async create(storeSlugId: string): Promise<Cart> {
    const url = this.getCartApiUrl()
    return this.requestService.post(url, { storeSlugId })
  }

  async addLine(cartId: CartId | null, line: AddLineItemQueryV2): Promise<Cart> {
    const path = cartId ? `/lines?cartId=${cartId}` : `/lines`
    const url = this.getCartApiUrl(path)
    return this.requestService.post(url, line)
  }

  async get(id: string): Promise<Cart> {
    const url = this.getCartApiUrl(`/${id}`)
    return this.requestService.get(url)
  }

  async findOne(queryParams: IFindOneParams): Promise<Cart> {
    const url = this.getCartApiUrl()
    return this.requestService.get<Cart>(url, {
      params: queryParams
    })
  }

  async removeLine(cartId: CartId, lineId: string, storeId: string): Promise<Cart> {
    const url = this.getCartApiUrl(`/${cartId}/lines/${lineId}`)
    return this.requestService.delete(url, {
      params: { storeId }
    })
  }

  async updateLineQuantity({ cartId, lineId, quantity, storeId }: IUpdateLineQuantityQuery): Promise<Cart> {
    const url = this.getCartApiUrl(`/${cartId}/lines/${lineId}/quantity`)
    return this.requestService.put(url, {
      quantity,
      storeId
    })
  }

  async updateLineOffer(
    cartId: CartId,
    lineId: string,
    { storeId, productId, offerReference, deliveryType }: UpdateLineOfferQuery
  ): Promise<Cart> {
    const url = this.getCartApiUrl(`/${cartId}/lines/${lineId}/offers`)
    return this.requestService.put(url, {
      storeId,
      productId,
      offerReference,
      deliveryType
    })
  }

  async updateStore(store: ICurrentStore, cartId: string): Promise<Cart> {
    const url = this.getCartApiUrl(`/${cartId}`)
    return this.requestService.patch(url, {
      storeId: store.id,
      storeSlugId: store.slug
    })
  }

  async createCheckout(cartId: CartId, storeId: ICurrentStore['id']): Promise<ICartCheckout> {
    const url = this.getCartApiUrl(`/${cartId}/checkouts`)
    return this.requestService.post<ICartCheckout>(url, {
      storeId
    })
  }

  async countLines(cartId?: CartId | null): Promise<number> {
    const url = this.getCartApiUrl(`/lines/count`)
    return this.requestService.get<number>(url, {
      params: { ...(cartId ? { cartId } : null) }
    })
  }
}
