import type { RedirectLoginResult } from '@auth0/auth0-spa-js'
import type { ILoginParamsDTO } from '../../src/contexts/auth/domain/login-dto.model'
import type { AccessToken } from '../../src/contexts/auth/domain/token.model'

export function mockHandleRedirect(): Promise<RedirectLoginResult<ILoginParamsDTO>> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Promise.resolve({
    appState: {
      redirectUriAfterLogin: '/'
    }
  })
}

export function mockIsAuthenticated(): boolean {
  return window.NUXT_MOCKS?.auth0?.isLogin || false
}

export function mockGetTokenSilently(): Promise<AccessToken> {
  return Promise.resolve('mock-token-for-cy')
}

export function mockSetLoginAuth0(win?: Window): void {
  let tmpWin = win

  if (!tmpWin) tmpWin = window

  Object.defineProperty(tmpWin, 'NUXT_MOCKS', {
    configurable: false,
    get: () => ({ auth0: { isLogin: true } }),
    set: () => null
  })
}

export default {
  mockSetLoginAuth0,
  mockIsAuthenticated,
  mockHandleRedirect
}
