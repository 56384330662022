import type { User } from '../domain/user.model'
import type { IUserRepository } from '../infrastructure/interfaces/i-user.repository'

export class UpdateUserInformation {
  constructor(public readonly userRepository: IUserRepository) {}

  async execute(userInfo: User): Promise<User> {
    return this.userRepository.updateUserInformation(userInfo)
  }
}
