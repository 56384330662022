import type { ProductRecommendAvailability } from '@domain/product-recommend/product-recommend-availability.model'
import type { IRecommendationRepository } from '@domain/algolia-recommendation/recommendation-repository.interface'
import type { ICurrentStore } from '@/domain/current-store/current-store.interface'

export class GetRecommendation {
  constructor(private recommendationRepository: IRecommendationRepository) {}

  async execute(
    productIds: string[],
    currentStore: ICurrentStore | null,
    isFid?: boolean
  ): Promise<ProductRecommendAvailability[] | null> {
    if (!productIds.length) return null
    return this.recommendationRepository.getFrequentlyBoughtTogetherWithOffers(productIds, currentStore, isFid)
  }
}
