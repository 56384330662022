import type { LogisticOfferForSale } from '@invivodf/module-logistic-offer-client-sdk'
import { LogisticRoute } from '@invivodf/module-logistic-offer-client-sdk'

export enum OfferLogisticRoute {
  CLICK_AND_COLLECT = LogisticRoute.ClickAndCollect,
  HOME_DELIVERY = LogisticRoute.HomeDelivery,
  STORE_DELIVERY = LogisticRoute.StoreDelivery
}

export type Logistic = {
  route: OfferLogisticRoute
  offers: LogisticOfferForSale[]
}

export interface OfferDTO {
  reference: string
  productId: string
  sellerId: string
  supplierId: string
  priceIncludingVat: number
  priceExcludingVat: number
  stock: number
  logistic: Logistic
}
