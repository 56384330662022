import { mongoCacheInstance } from '@infrastructure/cache/mongo-cache'
import { fileCacheInstance } from '@infrastructure/cache/fs-cache'
import { useConfig } from '@/application/composables/config'

export const enum CacheType {
  File = 'file',
  Mongo = 'mongo'
}

const currentCacheInstance = () => {
  const { getEnv } = useConfig()

  switch (getEnv('CACHE_TYPE')) {
    case CacheType.Mongo:
      if ((process.server && mongoCacheInstance.isActivated) || process.client) return mongoCacheInstance
      return fileCacheInstance
    case CacheType.File:
      return fileCacheInstance
    default:
      return fileCacheInstance
  }
}

export const cacheInstance = currentCacheInstance()

export default {
  cacheInstance
}
