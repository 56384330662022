import type { IUserState } from '@application/store/user/interface'
import type { MutationTree } from 'vuex'
import type { EUserStatus } from '@application/store/user/constants'
import { USER_ACTIONS } from '@application/store/user/constants'
import type { User } from '@contexts/user'

const userMutations: MutationTree<IUserState> = {
  [USER_ACTIONS.SET_CURRENT_USER]: (state: IUserState, user: User) => {
    state.current = user
  },
  [USER_ACTIONS.SET_USER_CONNECTION]: (state: IUserState, connection: EUserStatus) => {
    state.connectionStatus = connection
  },
  [USER_ACTIONS.SET_USER_LOADED]: (state: IUserState, isLoaded: boolean) => {
    state.isLoaded = isLoaded
  }
}

export default userMutations
