import type { ICartRepository } from '@contexts/cart/domain/cart-repository.interface'
import type { ICartCheckout } from '@contexts/cart/domain/cart.model'
import type { ICurrentStore } from '@/domain/current-store/current-store.interface'

interface ICreateCheckoutParam {
  cartRepository: ICartRepository
}

export class CreateCheckout {
  public readonly cartRepository: ICartRepository

  constructor({ cartRepository }: ICreateCheckoutParam) {
    this.cartRepository = cartRepository
  }

  async execute(cartId: string, storeId: ICurrentStore['id']): Promise<ICartCheckout> {
    return this.cartRepository.createCheckout(cartId, storeId)
  }
}
