import type { Optins } from '../../domain/optins.model'

export type IUserOptinsRequest = {
  email: {
    active: boolean
    value: string
  }
  sms: {
    active: boolean
    value: string
    indicator: string
  }
}

export class OptinsMapper {
  static toRequest(source: Optins): IUserOptinsRequest {
    return {
      email: {
        active: source.emailActive,
        value: source.emailAddress
      },
      sms: {
        active: source.smsActive,
        value: source.phoneNumber,
        indicator: source.phoneNumberIndicator
      }
    }
  }
}
