import type { IRequest } from '@application/composables/request'
import { StoreReviews } from '@domain/store-reviews/store-reviews.model'

export class StoreReviewsDataSource {
  constructor(public requestService: IRequest, public host: string | null) {}

  async fetchStoreReviews(
    currentShopId: string,
    offset: number | null,
    limit: number | null,
    sort: string | null,
    sortDirection: string | null
  ): Promise<StoreReviews | null> {
    const offsetLimitQuery = `?offset=${offset ?? 0}&limit=${limit ?? 5}`
    const sortQuery = sort ? `&sort=${sort}&sortdirection=${sortDirection}` : ''
    const url = `${this.host}/store/${currentShopId}${offsetLimitQuery}${sortQuery}`
    return this.requestService
      .get<StoreReviews>(url)
      .then((data) => {
        if (!data) return null
        const { id, placeName, reviews, reviewCount, providerUrl, scoreAverage } = data
        return new StoreReviews(id, placeName, reviewCount, scoreAverage, providerUrl, reviews)
      })
      .catch(() => null)
  }
}
