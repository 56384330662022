import type { IStoreLocatorRepository } from '@contexts/store-locator/domain/store-locator-repository.interface'
import type { ICurrentStore } from '@/domain/current-store/current-store.interface'
import type { IUseCookie } from '@/application/composables/cookie'

export class SaveCurrentStore {
  constructor(public readonly storeLocatorRepository: IStoreLocatorRepository) {}

  execute(data: ICurrentStore, isUserConnected: boolean, useCookie: IUseCookie): Promise<void> {
    return this.storeLocatorRepository.saveCurrentStore(data, isUserConnected, useCookie)
  }
}
