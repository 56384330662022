import type { IStoreLocatorRepository } from '@contexts/store-locator/domain/store-locator-repository.interface'
import type { ICurrentStore } from '@/domain/current-store/current-store.interface'

export class GetStoreById {
  constructor(public readonly storeLocatorRepository: IStoreLocatorRepository) {}

  execute(id: string): Promise<ICurrentStore | null> {
    return this.storeLocatorRepository.getStoreById(id)
  }
}
