import type { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { RequestErrorModel } from '@application/composables/request/request-error.model'
import logger from '@utils/logger/logger'
import { axios } from '@/utils/esmParser'
import { showError, tryUseNuxtApp } from '#imports'
import type { IRequest, IRequestOptions, PaginatedData } from './request.interface'
import { useConfig } from '../config'

export const interceptorSuccess = (success: AxiosResponse<unknown, unknown>): AxiosResponse<unknown, unknown> => success
export const interceptorError = (error: AxiosError): Promise<AxiosError> => {
  if (error.response?.status !== 404) {
    logger.error(error.message, error)
  }

  if (error.response?.status === 580) {
    if (process.client) {
      window.location.href = '/maintenance'
    } else {
      showError({ statusCode: 580 })
    }
  }

  return Promise.reject(error)
}

const { getIzanamiUrl } = useConfig()

const shouldAddLocaleHeader = (url?: string) => {
  const izanameUrl = getIzanamiUrl()
  if (izanameUrl && url?.startsWith(izanameUrl!)) return false
  return true
}

export class RequestService implements IRequest {
  public httpClient: AxiosInstance

  static throwRequestError = (error: AxiosError): never => {
    throw new RequestErrorModel(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error.response?.data as any)?.message || error.response?.data,
      error.response?.status,
      error.response?.data
    )
  }

  constructor() {
    this.httpClient = axios.create()

    // TODO : XJU - voir si cela fonctione bien
    this.httpClient.interceptors.response.use(interceptorSuccess, interceptorError)

    this.httpClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      const { $i18n } = tryUseNuxtApp() ?? {}
      if ($i18n && shouldAddLocaleHeader(config.url)) {
        config.headers.set('Idf-Locale', $i18n.localeProperties.value.language)
      } else if (!$i18n) {
        logger.warn('httpClient: $i18n is not available')
      }

      return config
    })
  }

  public async get<T>(url: string, options?: IRequestOptions): Promise<T> {
    return this.httpClient
      .get<T>(url, options)
      .then((res) => res.data)
      .catch(RequestService.throwRequestError)
  }

  public async getPaginatedData<T>(url: string, options?: IRequestOptions): Promise<PaginatedData<T>> {
    return this.httpClient
      .get<T>(url, options)
      .then((res) => {
        const contentRange = res.headers['content-range']
        if (contentRange) {
          const nbOfElements = +contentRange.split('/')[1]
          return { data: res.data, pagination: { total: nbOfElements } }
        }
        throw new Error('Error: tried to get paginated data but no content-range header was found')
      })
      .catch(RequestService.throwRequestError)
  }

  public async post<T>(url: string, data?: unknown, options?: IRequestOptions): Promise<T> {
    return this.httpClient
      .post<T>(url, data, options)
      .then((res) => res.data)
      .catch(RequestService.throwRequestError)
  }

  public async patch<T>(url: string, data?: unknown, options?: IRequestOptions): Promise<T> {
    return this.httpClient
      .patch<T>(url, data, options)
      .then((res) => res.data)
      .catch(RequestService.throwRequestError)
  }

  public async delete<T>(url: string, options?: IRequestOptions): Promise<T> {
    return this.httpClient
      .delete<T>(url, options)
      .then((res) => res.data)
      .catch(RequestService.throwRequestError)
  }

  public async put<T>(url: string, data?: unknown, options?: IRequestOptions): Promise<T> {
    return this.httpClient
      .put<T>(url, data, options)
      .then((res) => res.data)
      .catch(RequestService.throwRequestError)
  }
}

export const createRequestService = (): RequestService => {
  return new RequestService()
}
