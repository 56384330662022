import { CommonSeo } from '@domain/contentful/common-seo.model'
import type { TypeCommonSeo } from '@invivodf/contentful-types-retail'
import { ContentfulMapper } from '../contentful.mapper'

export class CommonSeoMapper {
  static extract(commonSeo: TypeCommonSeo<'WITHOUT_UNRESOLVABLE_LINKS', string> | null): CommonSeo | null {
    if (!commonSeo?.fields) return null
    return new CommonSeo(
      commonSeo.fields.title,
      ContentfulMapper.extractImage(commonSeo.fields.headerIcon || null),
      commonSeo.fields.content,
      commonSeo.fields.display,
      commonSeo.fields.position
    )
  }
}
