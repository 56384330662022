import { contentfulClient, contentfulContentTypes } from '@infrastructure/contentful'
import { cacheInstance } from '@infrastructure/cache'
import { GetConfig } from './use-cases/GetConfig'
import { ConfigRepository } from './infrastructure/config.repository'
import { ConfigDataSource } from './infrastructure/data-sources/config.data-source'

const contentfulContentType = contentfulContentTypes.COMMON_CONFIG
const configDataSource = new ConfigDataSource(contentfulClient, contentfulContentType)
const configRepository = new ConfigRepository(configDataSource, cacheInstance)

const getConfig = new GetConfig(configRepository)

export { getConfig }
