import type { IStoreReviewsRepository } from '@contexts/store-locator/domain/store-reviews-repository.interface'
import type { StoreReviewsDataSource } from '@contexts/store-locator/infrastructure/data-sources/store-reviews.data-source'
import type { StoreReviews } from '@/domain/store-reviews/store-reviews.model'

export class StoreReviewsRepository implements IStoreReviewsRepository {
  constructor(private readonly customerReviewsDataSource: StoreReviewsDataSource) {}

  async findStoreReviews(
    currentShopId: string,
    offset: number | null,
    limit: number | null,
    sort: string | null,
    sortDirection: string | null
  ): Promise<StoreReviews | null> {
    return this.customerReviewsDataSource.fetchStoreReviews(currentShopId, offset, limit, sort, sortDirection)
  }
}
