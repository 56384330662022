import { useConfig } from '@/application/composables/config'

const DB_TIMEOUT_MS = 1000
const DB_MAX_IDLE_TIME_MS = 30000

export const getMongoConfig = () => {
  const { getEnv } = useConfig()

  const url = getEnv('MONGO_URL')

  const MONGO_URI = `${url}/?maxIdleTimeMS=${DB_MAX_IDLE_TIME_MS}&maxConnecting=4&connectTimeoutMS=${DB_TIMEOUT_MS}&serverSelectionTimeoutMS=${DB_TIMEOUT_MS}`
  const MONGO_DB = getEnv('MONGO_DB') || undefined

  return {
    MONGO_URL: url,
    MONGO_URI,
    MONGO_DB
  }
}

export default {
  getMongoConfig
}
