import type {
  FrequentlyBoughtTogetherQuery,
  RecommendationsQuery,
  RecommendClient,
  RecommendModel,
  RecommendSearchOptions,
  TrendingItemsQuery
} from '@algolia/recommend/dist/recommend'
import type { MultipleQueriesResponse } from '@algolia/client-search'
import type { AlgoliaIndexMapper } from '@infrastructure/algolia/algolia-index.mapper'
import type { Cache } from '@infrastructure/cache/utils/cache.interface'
import { hashCode } from '@utils/hash'
import type { IIzanamiDatasource } from '@domain/izanami/izanami-repository.interface'
import type {
  IAlgoliaRecommendationDataSource,
  IFacetParam
} from '@domain/algolia-recommendation/recommendation-datasource.interface'
import type { ICurrentStore } from '@/domain/current-store/current-store.interface'

export class AlgoliaRecommendationDataSource implements IAlgoliaRecommendationDataSource {
  constructor(
    public readonly recommendClient: RecommendClient,
    public readonly algoliaIndexMapper: AlgoliaIndexMapper,
    public readonly izanamiDatasource: IIzanamiDatasource,
    public readonly cacheInstance: Cache,
    public readonly recommendFeatureFlipId: string | null,
    public readonly environment: string
  ) {}

  maxRecommendations = 8

  async getTrendingItems<T>(
    facetParams: IFacetParam[],
    selectedStore: ICurrentStore | null,
    locale: string
  ): Promise<MultipleQueriesResponse<T> | null> {
    const isEnabled = await this.isEnabled()
    if (!isEnabled) return Promise.resolve(null)

    const cacheName = `algolia-trending-${locale}${hashCode([JSON.stringify(facetParams), selectedStore?.id || ''])}`
    let multipleResult = await this.cacheInstance.get<MultipleQueriesResponse<T>>(cacheName, {
      checkOnline: !process.server
    })

    if (!multipleResult?.results?.length) {
      multipleResult = await this.recommendClient
        .getTrendingItems<T>(this.buildTrendingItemsQuery(facetParams, selectedStore))
        .catch(() => Promise.resolve(null))

      if (!multipleResult?.results?.length) return null

      this.cacheInstance.set(cacheName, multipleResult)
    }

    return multipleResult
  }

  async getFrequentlyBoughtTogether<T>(
    productsIds: string[],
    selectedStore: ICurrentStore | null
  ): Promise<MultipleQueriesResponse<T> | null> {
    const isEnabled = await this.isEnabled()
    if (!isEnabled) return Promise.resolve(null)

    const response = await this.recommendClient
      .getFrequentlyBoughtTogether<T>(this.buildBoughtTogetherItemsQuery(selectedStore, productsIds))
      .catch(() => Promise.resolve(null))

    return response
  }

  async getRelatedProducts<T>(
    productIds: string[],
    selectedStore: ICurrentStore | null
  ): Promise<MultipleQueriesResponse<T> | null> {
    const isEnabled = await this.isEnabled()
    if (!isEnabled) return null
    const response = await this.recommendClient
      .getRecommendations<T>(this.buildRelatedProductsQuery(productIds, selectedStore))
      .catch(() => Promise.resolve(null))
    return response
  }

  buildTrendingItemsQuery(facetParams: IFacetParam[], selectedStore: ICurrentStore | null): TrendingItemsQuery[] {
    return facetParams.map((facetParam) => {
      const queryParameters: RecommendSearchOptions = {
        filters: AlgoliaRecommendationDataSource.buildFilters(selectedStore),
        query: facetParam.facetQuery || undefined
      }

      return {
        indexName: this.algoliaIndexMapper.recommendProductsIndexName(),
        threshold: 0,
        maxRecommendations: this.maxRecommendations,
        facetName: facetParam.facetName,
        facetValue: facetParam.facetValue,
        queryParameters
      }
    })
  }

  buildBoughtTogetherItemsQuery(
    selectedStore: ICurrentStore | null,
    objectIDs: string[]
  ): FrequentlyBoughtTogetherQuery[] {
    return objectIDs.map((objectID) => {
      const queryParameters: RecommendSearchOptions = {
        filters: AlgoliaRecommendationDataSource.buildFilters(selectedStore)
      }

      return {
        indexName: this.algoliaIndexMapper.recommendProductsIndexName(),
        maxRecommendations: this.maxRecommendations,
        objectID,
        queryParameters,
        threshold: 0
      }
    })
  }

  buildRelatedProductsQuery = (productIds: string[], selectedStore: ICurrentStore | null): RecommendationsQuery[] =>
    productIds.map((productId): RecommendationsQuery => {
      return {
        indexName: this.algoliaIndexMapper.recommendProductsIndexName(),
        objectID: productId,
        model: 'related-products' as RecommendModel,
        maxRecommendations: 8,
        queryParameters: {
          filters: AlgoliaRecommendationDataSource.buildFilters(selectedStore)
        }
      }
    })

  static buildFilters = (selectedStore: ICurrentStore | null): string | undefined => {
    return selectedStore ? `storesWithStock:${selectedStore.id} OR hasStockForHomeDelivery:true` : undefined
  }

  isEnabled = (): Promise<boolean> => {
    if (this.environment === 'PRD') return Promise.resolve(true)
    if (!this.recommendFeatureFlipId) return Promise.resolve(false)
    return this.izanamiDatasource.isEnabled(this.recommendFeatureFlipId!)
  }
}
