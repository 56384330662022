import axios from 'axios'

export function checkDataOnline<T>(name: string, apiCache: string): Promise<T | null> {
  const domain = process.server ? process.env.NUXT_PUBLIC_BASE_URL : window.location.origin

  return axios
    .get(`${domain}${apiCache}${name}`)
    .then(({ data }) => {
      return data as T
    })
    .catch(() => {
      return null
    })
}

export default {
  checkDataOnline
}
