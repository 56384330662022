import type { CommonSeo } from '@domain/contentful/common-seo.model'
import type { CategoryConfig } from '@contexts/config/domain/category-config.model'
import type { CommonImageItem } from '@/domain/contentful/common-image-item.model'
import type { CommonAdaptativeImageItem } from '@/domain/contentful/common-adaptative-image-item.model'
import type { PromotionContent } from '@/domain/contentful/promotion-content.model'

export class CommonConfig {
  constructor(
    public title: string,
    public logo: CommonAdaptativeImageItem | null,
    public categories: CategoryConfig[] | null,
    public alertInfo: CommonSeo | null,
    public storeHeader: CommonImageItem | null,
    public promotionContent: PromotionContent | null
  ) {}

  toJSON(): CommonConfig {
    return { ...this }
  }
}
