export class ArticleCategory {
  constructor(
    public name: string,
    public slug: string,
    public parentCategory: ArticleCategory | null,
    public order?: number
  ) {}

  toJSON(): ArticleCategory {
    return { ...this }
  }
}
