export type StoreReviewFeedback = {
  createdAt: string
  author: string
  score: number
  comment: string
}

export class StoreReviews {
  constructor(
    public id: string,
    public placeName: string,
    public reviewCount: number,
    public scoreAverage: number,
    public providerUrl: string,
    public reviews: StoreReviewFeedback[] | null
  ) {}

  toJSON(): StoreReviews {
    return { ...this }
  }

  isStoreReviews = true

  isProductReviews = false
}
