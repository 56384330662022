import type { CommonImageItem } from './common-image-item.model'
import type { CommonLinkItem } from './common-link-item.model'

export class CommonListLink {
  isCommonListLink: boolean

  constructor(public title: string, public icon: CommonImageItem | null, public links: CommonLinkItem[]) {
    this.isCommonListLink = true
  }

  toJSON(): CommonListLink {
    return { ...this }
  }
}
