import validate from "/builds/invivoretail/enseignes/enseignes.com/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/builds/invivoretail/enseignes/enseignes.com/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "product-list-page": () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/middleware/product-list-page.ts"),
  "product-page": () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/middleware/product-page.ts"),
  "router-auth": () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/middleware/router-auth.ts"),
  "shop-page": () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/middleware/shop-page.ts")
}