import { CartRepository } from '@contexts/cart/infrastructure/cart.repository'
import { CartDataSource } from '@contexts/cart/infrastructure/data-sources/cart.data-source'
import { authRequestService } from '@application/composables/auth-request'
import { AddLine } from '@contexts/cart/use-cases/AddLine'
import { GetCart } from '@contexts/cart/use-cases/GetCart'
import { RemoveLine } from '@contexts/cart/use-cases/RemoveLine'
import { UpdateStore } from '@contexts/cart/use-cases/UpdateStore'
import { useConfig } from '@application/composables/config'
import { GetRecommendation } from '@contexts/cart/use-cases/GetRecommendation'
import { recommendationRepository } from '@infrastructure/algolia'
import { CartDataSourceV2 } from '@contexts/cart/infrastructure/data-sources/v2/cart.data-source-v2'
import { UpdateLineQuantity } from './use-cases/UpdateLineQuantity'
import { UpdateLineOffer } from './use-cases/UpdateLineOffer'
import { CreateCheckout } from './use-cases/CreateCheckout'
import { CountLines } from './use-cases/CountLines'

const { getCartApiUrl, getCartV2ApiUrl } = useConfig()
const cartDataSource = new CartDataSource(authRequestService, getCartApiUrl)
const cartDataSourceV2 = new CartDataSourceV2(authRequestService, getCartV2ApiUrl)
const cartRepository = new CartRepository(cartDataSource, cartDataSourceV2)

const getCart = new GetCart({ cartRepository })
const getRecommendation = new GetRecommendation(recommendationRepository)
const addLine = new AddLine({ cartRepository })
const removeLine = new RemoveLine({ cartRepository })
const updateLineQuantity = new UpdateLineQuantity({ cartRepository })
const updateLineOffer = new UpdateLineOffer({ cartRepository })
const updateStore = new UpdateStore({ cartRepository })
const createCheckout = new CreateCheckout({ cartRepository })
const countLines = new CountLines({ cartRepository })

export {
  getCart,
  getRecommendation,
  addLine,
  removeLine,
  updateLineQuantity,
  updateLineOffer,
  updateStore,
  createCheckout,
  countLines
}
