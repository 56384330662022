// TODO: move auth repository to application/composables
import { AuthenticationRepository } from '@contexts/auth/infrastructure/authentication.repository'
import { useConfig } from '@application/composables/config'
import { AuthRequestService } from './auth-request.service'
import type { IAuthRequestParam } from './auth-request-param.interface'

const { getEnv, getBaseUrl } = useConfig()

const authenticationRepository = new AuthenticationRepository(getEnv, getBaseUrl)
const params: IAuthRequestParam = { authenticationRepository }
const authRequestService = new AuthRequestService(params)

export { authRequestService }
