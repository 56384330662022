import type { Address } from '@/domain/address'
import type { Optins } from './optins.model'

export enum UserSegments {
  loyalty = 'FID'
}

export type ILoyaltyLight = {
  cardNumber: string
  fileNumber: string
  loyaltyPoints: number
  totalVouchersAmount: number
  vouchersCount: number
}

export enum Country {
  FRANCE = 'FRANCE',
  SPAIN = 'SPAIN'
}

export class User {
  constructor(
    public email: string,
    public addresses: Address[],
    public firstName: string,
    public lastName: string,
    public id: string,
    public segments: UserSegments[],
    public country?: Country,
    public loyalty?: ILoyaltyLight,
    public customUserId?: string,
    public favoriteStoreId?: string,
    public optins?: Optins,
    public errors?: string[]
  ) {}

  static generateNotConnectedUser(customUserId: string): User {
    return new User('', [], '', '', '', [], undefined, undefined, customUserId)
  }
}
