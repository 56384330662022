import type {
  AddLineItemQueryV2,
  CartWithErrors,
  UpdateLineItemOfferQuery
} from '@invivodf/module-cart-retail-interfaces'
import type { CartId, ICartCheckout } from '@contexts/cart/domain/cart.model'
import type { IRequest } from '@application/composables/request'
import type { IUpdateLineQuantityQuery } from '@contexts/cart/domain/update-line-quantity-query.interface'
import type { ICurrentStore } from '@domain/current-store/current-store.interface'

interface IFindOneParams {
  storeId: string
  cartId?: string
}

export class CartDataSourceV2 {
  constructor(public requestService: IRequest, public getCartV2ApiUrl: (path?: string) => string) {}

  async addLine(cartId: CartId | null, line: AddLineItemQueryV2): Promise<CartWithErrors> {
    const path = cartId ? `/lines?cartId=${cartId}` : `/lines`
    const url = this.getCartV2ApiUrl(path)
    return this.requestService.post(url, line)
  }

  async findOne(queryParams: IFindOneParams): Promise<CartWithErrors> {
    const url = this.getCartV2ApiUrl()
    return this.requestService.get(url, {
      params: queryParams
    })
  }

  async updateLineQuantity({ cartId, lineId, quantity, storeId }: IUpdateLineQuantityQuery): Promise<CartWithErrors> {
    const url = this.getCartV2ApiUrl(`/${cartId}/lines/${lineId}/quantity`)
    return this.requestService.put(url, {
      quantity,
      storeId
    })
  }

  async updateStore(store: ICurrentStore, cartId: string): Promise<CartWithErrors> {
    const url = this.getCartV2ApiUrl(`/${cartId}`)
    return this.requestService.put(url, {
      storeId: store.id
    })
  }

  async removeLine(cartId: CartId, lineId: string): Promise<CartWithErrors> {
    const url = this.getCartV2ApiUrl(`/${cartId}/lines/${lineId}`)
    return this.requestService.delete(url)
  }

  async createCheckout(cartId: CartId, storeId: string): Promise<ICartCheckout> {
    const url = this.getCartV2ApiUrl(`/${cartId}/checkouts`)
    return this.requestService.post(url, {
      params: { storeId }
    })
  }

  async updateLineOffer(
    cartId: CartId,
    lineId: string,
    { offerReference, deliveryType }: UpdateLineItemOfferQuery
  ): Promise<CartWithErrors> {
    const url = this.getCartV2ApiUrl(`/${cartId}/lines/${lineId}/offers`)
    return this.requestService.put(url, {
      offerReference,
      deliveryType
    })
  }
}
