import { StoreReviewsRepository } from '@contexts/store-locator/infrastructure/store-reviews.repository'
import { requestService } from '@application/composables/request'
import { GetStoreReviews } from '@contexts/store-locator/use-cases/get-store-reviews/get-store-reviews'
import { useConfig } from '@application/composables/config'
import { StoreReviewsDataSource } from '@contexts/store-locator/infrastructure/data-sources/store-reviews.data-source'

const { getEnv } = useConfig()
const host = getEnv('REVIEWS_API')

const customerReviewsDataSource = new StoreReviewsDataSource(requestService, host)
const customerReviewsRepository = new StoreReviewsRepository(customerReviewsDataSource)

const getStoreReviews = new GetStoreReviews(customerReviewsRepository)

export { getStoreReviews }
