import type { IRequest } from '@application/composables/request'

export class UserDataSource {
  constructor(public requestService: IRequest, public getUsersApiUrl: (path?: string) => string) {}

  async updateUserFavoriteStoreId(storeId: string | null): Promise<void> {
    const urlUser = this.getUsersApiUrl(`/me`)

    await this.requestService.put(urlUser, { favoriteStoreId: storeId }).catch((err) => {
      throw err
    })
  }
}
