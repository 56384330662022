import type { ILine } from '@contexts/cart/domain/line.model'
import type { ISubtotal } from '@contexts/cart/domain/subtotal.model'
import type { ICartState } from './interface'

export default (): ICartState => ({
  id: '',
  storeSlugId: '',
  lines: [] as ILine[],
  customerId: undefined,
  subtotal: {} as ISubtotal,
  total: '',
  estimatedShippingCost: '',
  status: '',
  checkoutId: undefined
})
