import type { Address } from '@/domain/address'
import type { IUserRepository } from '../infrastructure/interfaces/i-user.repository'

export class UpdateAddress {
  constructor(public readonly userRepository: IUserRepository) {}

  async execute(newAddress: Address): Promise<Address> {
    return this.userRepository.updateAddress(newAddress)
  }
}
