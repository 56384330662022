import { UserSegments } from '@contexts/user/domain/user.model'
import type { GetterTree } from 'vuex'
import type { IUserState } from './interface'
import type { IRootState } from '../state.interface'
import { EUserStatus } from './constants'

const useGetters: GetterTree<IUserState, IRootState> = {
  isFID(state: IUserState) {
    return Boolean(state.current?.segments?.includes(UserSegments.loyalty))
  },
  isConnected(state: IUserState) {
    return [EUserStatus.CONNECTED, EUserStatus.FID_CONNECTED].includes(state.connectionStatus)
  }
}

export default useGetters
