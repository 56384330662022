import type { MutationTree } from 'vuex'
import type { IAxeptioState } from '@application/store/axeptio/interface'
import { axeptioTypes } from '@application/store/axeptio/constants'

const axeptioMutations: MutationTree<IAxeptioState> = {
  [axeptioTypes.SET_READY]: (state: IAxeptioState, ready: boolean) => {
    state.ready = ready
  }
}

export default axeptioMutations
