import type { LogisticRoute } from '@invivodf/module-logistic-offer-client-sdk'

export enum ProductDiscountScope {
  ARTICLE = 'article',
  GROUP = 'group'
}
export interface IProductDiscount {
  amount?: number
  unit?: string
  originalPrice?: number
  segment?: string
  scope?: ProductDiscountScope
  minQuantity?: number
}
export interface IProductMeta {
  page?: number
  queryId?: string
}
export interface IProductPrice {
  price: number
  isIndicativePrice: boolean
  discount?: IProductDiscount
}
export type IProductAvailability = Partial<Record<LogisticRoute, boolean>>
