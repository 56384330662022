import type { IStoreReviewsRepository } from '@contexts/store-locator/domain/store-reviews-repository.interface'
import type { StoreReviews } from '@/domain/store-reviews/store-reviews.model'

export class GetStoreReviews {
  constructor(public readonly customerReviewsRepository: IStoreReviewsRepository) {}

  async execute(
    currentShopId: string,
    offset: number | null,
    limit: number | null,
    sort: string | null,
    sortDirection: string | null
  ): Promise<StoreReviews | null> {
    return this.customerReviewsRepository.findStoreReviews(currentShopId, offset, limit, sort, sortDirection)
  }
}
