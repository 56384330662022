const oneHourInSecond = 60 * 60

export interface CacheSpecicConfig {
  name: string
  ttl: number
  activate: boolean
}

export interface CacheConfig {
  DEFAULT_CACHE_TTL: number
  SPECIFIC_CONFIG_FOR_ITEMS: CacheSpecicConfig[]
}

const cacheConfig: CacheConfig = {
  DEFAULT_CACHE_TTL: oneHourInSecond,
  SPECIFIC_CONFIG_FOR_ITEMS: [
    {
      name: 'algolia-trending',
      ttl: oneHourInSecond * 24,
      activate: true
    }
  ]
}

export function getConfigFor(name: string): CacheSpecicConfig {
  return (
    cacheConfig.SPECIFIC_CONFIG_FOR_ITEMS.find((cache) => name.includes(cache.name)) || {
      name: 'generic',
      activate: true,
      ttl: cacheConfig.DEFAULT_CACHE_TTL
    }
  )
}

export default cacheConfig
