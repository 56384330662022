import type { IProductAvailability } from '@domain/product/product.interface'
import { LogisticRoute } from '@invivodf/module-logistic-offer-client-sdk'
import type { OfferUnifiedDTO } from '@infrastructure/offer'
import type { ShippingDTO } from '@invivoretail/module-offers-interfaces'

export class OfferMapper {
  static extractAvailabilitiesFromOfferUnified = (offers: OfferUnifiedDTO[] | null): IProductAvailability => {
    const defaultAvailabilities: IProductAvailability = {
      [LogisticRoute.ClickAndCollect]: false,
      [LogisticRoute.HomeDelivery]: false
    }
    if (!offers?.length) return defaultAvailabilities

    return offers
      .flatMap((offer) => offer.shippings)
      .reduce((availabilities: IProductAvailability, shipping: ShippingDTO) => {
        if (
          shipping.logisticRoute === LogisticRoute.ClickAndCollect ||
          shipping.logisticRoute === LogisticRoute.StoreDelivery
        )
          return {
            ...availabilities,
            [LogisticRoute.ClickAndCollect]: shipping.stock > 0
          }

        if (shipping.logisticRoute === LogisticRoute.HomeDelivery)
          return {
            ...availabilities,
            [LogisticRoute.HomeDelivery]: shipping.stock > 0
          }

        return availabilities
      }, defaultAvailabilities)
  }
}
