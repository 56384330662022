import { useConfig } from '@application/composables/config'
import { AuthenticationRepository } from './infrastructure/authentication.repository'
import { GetAccessToken } from './use-cases/GetAccessToken.usecase'
import { GetAuthenticatedId } from './use-cases/GetAuthenticatedId.usecase'
import { IsUserAuthenticated } from './use-cases/IsUserAuthenticated.usecase'
import { Logout } from './use-cases/Logout.usecase'
import { RedirectionToLoginPage } from './use-cases/RedirectionToLoginPage.usecase'
import { RetrieveAccessTokenAfterLogin } from './use-cases/RetrieveAccessTokenAfterLogin.usecase'

const { getEnv, getBaseUrl } = useConfig()
const authenticationRepository = new AuthenticationRepository(getEnv, getBaseUrl)

export const getAccessToken = new GetAccessToken(authenticationRepository)
export const getAuthenticatedId = new GetAuthenticatedId(authenticationRepository)
export const isUserAuthenticated = new IsUserAuthenticated(authenticationRepository)
export const logout = new Logout(authenticationRepository)
export const redirectionToLoginPage = new RedirectionToLoginPage(authenticationRepository)
export const handleRedirect = new RetrieveAccessTokenAfterLogin(authenticationRepository)
