import type { ICartRepository } from '@contexts/cart/domain/cart-repository.interface'
import type { ICart } from '@contexts/cart/domain/cart.model'
import type { IFindOneCartQuery } from '@contexts/cart/domain/find-one-cart-query.interface'

interface IGetCartParam {
  cartRepository: ICartRepository
}

export class GetCart {
  public readonly cartRepository: ICartRepository

  constructor({ cartRepository }: IGetCartParam) {
    this.cartRepository = cartRepository
  }

  async execute(queryParams: IFindOneCartQuery): Promise<ICart> {
    return this.cartRepository.findOne(queryParams)
  }
}
