import type { MutationTree } from 'vuex'
import type { ICart } from '@contexts/cart/domain/cart.model'
import { cartTypes } from './constants'
import type { ICartState } from './interface'

const cartMutations: MutationTree<ICartState> = {
  [cartTypes.SET_CART]: (state: ICartState, cart: ICart) => {
    Object.assign(state, cart)
  }
}

export default cartMutations
