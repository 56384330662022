import type { ICartRepository } from '@contexts/cart/domain/cart-repository.interface'

interface ICountLineParam {
  cartRepository: ICartRepository
}

export class CountLines {
  public readonly cartRepository: ICartRepository

  constructor({ cartRepository }: ICountLineParam) {
    this.cartRepository = cartRepository
  }

  async execute(cartId?: string | null): Promise<number> {
    return this.cartRepository.countLines(cartId)
  }
}
